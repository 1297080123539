import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
const FieldSet = props => {
    return (
        <>
            <Typography variant="h6">
                {props.title}
            </Typography>
            <Box my={5} />
            {props.children}
        </>
    )
}
FieldSet.propTypes = {
    title: PropTypes.string.isRequired
}
export default FieldSet