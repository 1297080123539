import { Button } from '@material-ui/core';
import React from 'react';
import { Edit } from 'react-feather';

const AlterarButton = ({ onClick = () => { }, title='Alterar' }) => {
    return (
        <>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<Edit />}
                onClick={() => onClick()}>
                {title}
            </Button>
        </>
    )
}

export default AlterarButton;