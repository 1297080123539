import {
  AppBar,
  Button as MuiButton,
  Card as MuiCard,
  CardContent, Divider as MuiDivider, Grid, Paper, Tab, Tabs, Typography
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import { Copy } from "react-feather";
import Helmet from "react-helmet";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import Alert from "../../../components/alert/Alert";
import BreadcrumbsNav from "../../../components/icarus/BreadcrumbsNav";
import FormEndereco from "../../../components/icarus/FormEndereco";
import Loading from "../../../components/Loading";
import WrapperTabPanel from "../../../components/WrapperTabPanel";
import EmpresaService from "../../../services/EmpresaService";
import PermissionUtil from "../../../utils/PermissionUtil";
import BrandingConsignado from "./BrandingConsignado";
import BrandingFGTS from "./BrandingFGTS";
import DadosEmpresa from "./DadosEmpresa";

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));



function Token() {
  const classes = useStyles();
  const [token, setToken] = useState(null);
  const [carregandoToken, setCarregandoToken] = useState(false);

  const copy = (idComponente) => {
    var copyText = document.getElementById(idComponente);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    alert("Copiado com sucesso!");
  };

  const showToken = () => {
    setCarregandoToken(true);
    EmpresaService.buscarToken()
      .then((ret) => {
        if (ret) {
          setToken(ret);
        }
      })
      .finally(() => setCarregandoToken(false));
  };

  return (
    <Paper elevation={4}>
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Token de Integração
          </Typography>

          <Accordion onChange={() => showToken()}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Clique aqui para visualizar sua API Key
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  {carregandoToken ? (
                    <Loading />
                  ) : (
                    <>
                      <Grid container spacing={6}>
                        <Grid item md={12} sm={12} xs={12}>
                          <input
                            style={{
                              fontWeight: "bold",
                              borderWidth: "0px",
                              backgroundColor: "transparent",
                              outline: "none",
                              width: "100%",
                            }}
                            type="text"
                            value={
                              token === null
                                ? "Token não gerado, clique no botão ao lado para gerar"
                                : token
                            }
                            id="tokenAcesso"
                          />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Button
                            style={{ marginLeft: 10 }}
                            variant="contained"
                            type="submit"
                            color="primary"
                            title="Copiar Token"
                            startIcon={<Copy />}
                            onClick={() => copy("tokenAcesso")}
                          >
                            Copiar
                          </Button>
                        </Grid>
                        <Grid item md={6}></Grid>
                      </Grid>
                    </>
                  )}
                </Grid>

                <Grid item md={12}>
                  <Typography style={{ fontWeight: "bold" }}>Dicas</Typography>
                  <Typography>
                    Lembre-se de atualizar o seu sistema toda vez que gerar uma
                    nova API Key.
                  </Typography>
                  <Typography>
                    A API Key é fundamental para manter a integridade das
                    comunicações feitas com a API, mantenha-a em segurança.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    </Paper>
  );
}



function Empresa() {
  const [endereco, setEndereco] = useState(null);
  const [carregandoEndereco, setCarregandoEndereco] = useState(true);

  const [salvando, setSalvando] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    carregarDados();
  }, []);

  const carregarDados = () => {
    setCarregandoEndereco(true);
    EmpresaService.buscar()
      .then((response) => {
        setEndereco(response);
      })
      .finally(() => setCarregandoEndereco(false));
  };

  const update = (endereco) => {
    setSalvando(true);
    EmpresaService.updateEmpresaEnderecoUsuarioLogado({
      cep: endereco.cep,
      endereco: endereco.endereco,
      numero: endereco.numero,
      bairro: endereco.bairro,
      estado: endereco.estado,
      cidade: endereco.cidade,
      complemento: endereco.complemento,
    })
      .then(() => {
        carregarDados();
        Alert.success("Sucesso", "Dados alterados com sucesso");
      })
      .finally(() => {
        setSalvando(false);
        setCarregandoEndereco(false);
      });
  };

  const handleChangeTab = (event, newValue) => {

    setTabIndex(newValue);

  };

  return (
    <React.Fragment>
      <Helmet title="Minha Empresa" />

      <Typography variant="h4" gutterBottom display="inline">
        Minha Empresa
      </Typography>

      <BreadcrumbsNav label="Minha Empresa" />

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Paper elevation={4}>
            <DadosEmpresa />
          </Paper>
          <Token />
          {PermissionUtil.hasPermission("manter_branding", user) &&
            <>
              <AppBar position="static" color="transparent">
                <Card>
                  <CardContent>
                    <Tabs
                      indicatorColor="primary"
                      textColor="primary"
                      value={tabIndex}
                      onChange={handleChangeTab}
                    >
                      <Tab
                        label="Branding FGTS"
                        {...a11yProps(0)}
                      />
                      <Tab
                        label="Branding Consignado"
                        {...a11yProps(1)}
                      />

                    </Tabs>
                  </CardContent>
                </Card>
              </AppBar>

              <WrapperTabPanel value={tabIndex} index={0}>
                <BrandingFGTS />
              </WrapperTabPanel>

              <WrapperTabPanel value={tabIndex} index={1}>
                <BrandingConsignado />
              </WrapperTabPanel>

            </>
          }

          <FormEndereco
            carregandoEndereco={carregandoEndereco}
            salvando={salvando}
            endereco={endereco}
            setEndereco={setEndereco}
            update={update}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Empresa;
