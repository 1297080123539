import {
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    makeStyles,
    Paper as MuiPaper,
    Typography
} from "@material-ui/core";
import { CloseIcon } from '@material-ui/data-grid';
import { spacing } from "@material-ui/system";
import React from 'react';
import styled from "styled-components/macro";


const Paper = styled(MuiPaper)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  root2: {
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));


function Dialog({ title, children, childrenButton, show = false, onRequestClose, maxWidth = 'sm', fullScreen = false, disableEscapeKeyDown=false, showClose=true }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(show);
    
    const handleClose = () => {
        setOpen(false);
        onRequestClose();
    };

    return (
        <Paper mt={4}>
            <React.Fragment>
                <MuiDialog
                    fullWidth={true}
                    maxWidth={maxWidth}
                    disableEscapeKeyDown={disableEscapeKeyDown}
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    disableBackdropClick
                    aria-labelledby="max-width-dialog-title">
                    {showClose &&
                        <DialogTitle id="max-width-dialog-title">
                            <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>{title}</Typography>
                            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                    }
                    <DialogContent>
                        {children}
                    </DialogContent>
                    <DialogActions>
                        {childrenButton}
                    </DialogActions>
                </MuiDialog>
            </React.Fragment>
        </Paper>
    )
}

export default Dialog
