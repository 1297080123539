"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var lightGreen = {
  50: '#49bc89',
  100: '#49bc89',
  200: '#49bc89',
  300: '#49bc89',
  400: '#49bc89',
  500: '#49bc89',
  600: '#49bc89',
  700: '#49bc89',
  800: '#28a073',
  900: '#28a073',
  A100: '#28a073',
  A200: '#28a073',
  A400: '#28a073',
  A700: '#28a073'
};
var _default = lightGreen;
exports.default = _default;