import { Button } from '@material-ui/core';
import React from 'react';
import { X } from 'react-feather';

const CancelarButton = ({ onClick, title = 'Cancelar', style, color = "primary", variant = "outlined" }) => {
    return (
        <>
            <Button
                variant={variant}
                color={color}
                startIcon={<X />}
                style={style}
                onClick={() => onClick()}>
                {title}
            </Button>
        </>
    )
}

export default CancelarButton;