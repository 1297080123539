import { Grid, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/styles';
import { Column } from 'primereact/column';
import { DataTable as PDatatable } from 'primereact/datatable';
import React, { useState } from 'react';
import { HeaderExporter } from './Exporter';
import Skeleton from '@material-ui/lab/Skeleton';

const DataTable = (props) => {

    const { rows = 10, id ="tabelaPrimefaces", objPage, carregando, renderAction, titleColumnAction = 'Ações', onPage = () => {} } = props;
    
    const [refTable, setRefTable] = useState();

    const actionTemplate = (rowData, column) => {
        return (
            <Grid container spacing={2}>
                {renderAction({rowData, column})}
            </Grid>
        );
    };

    
    return (
        <>
            
            {carregando &&
                <>
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                </>
            }

            {objPage
                ? (

                    !carregando &&


                    <div className="datatable-responsive-demo">
                        <div className="card">
                            <PDatatable
                                rowGroupMode={props.rowGroupMode}
                                groupField={props.groupField }
                                sortMode={props.sortMode }
                                sortField={props.sortField }
                                sortOrder={props.sortOrder}
                                rowClassName={props.rowClassName}
                                rowGroupHeaderTemplate={props.rowGroupHeaderTemplate}
                                rowGroupFooterTemplate={props.rowGroupFooterTemplate}
                                value={objPage.content}
                                paginator
                                rows={rows}
                                columnResizeMode="fit"
                                resizableColumns
                                totalRecords={objPage.totalElements}
                                lazy
                                id={id}
                                tableClassName={id}
                                className="p-datatable-sm p-datatable-striped p-datatable-responsive-demo"
                                responsive
                                first={objPage.pageable.pageNumber * rows}
                                emptyMessage="Nenhum registro encontrado para os critérios informados."
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                loading={carregando}
                                onPage={(event) => onPage(event.page)}
                                currentPageReportTemplate="{first} - {last} Total {totalRecords}"
                                ref={(el) => setRefTable(el)}
                                header={<HeaderExporter objPage={objPage} id={id} refTable={refTable} props={props} />}
                            >
                                {props.children}

                                {renderAction && <Column body={actionTemplate} style={props.widthColumnAction && { width: props.widthColumnAction}} header={titleColumnAction} />}
                            </PDatatable>
                        </div>
                    </div>
                )
                : !carregando && <Typography>Nenhum registro encontrado para os critérios informados.</Typography>}
        </>
    )
}

export default withTheme(DataTable)
