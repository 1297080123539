import * as types from "../../constants";

const initialState = {
  authSignInRequest: false,
}

export default function reducer(state = initialState, actions) {
  
  switch (actions.type) {
    case types.AUTH_SIGN_IN_REQUEST:
      return {
        ...state,
        authSignInRequest: true
      };
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: actions.user,
        authSignInRequest: false
      };
    case types.AUTH_SIGN_IN_FAILURE:
      return {
        ...state,
        authSignInRequest: false
      };
    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        user: undefined,
      };
    case types.AUTH_RESET_PASSWORD_SUCCESS:
      return {
          ...state,
          msgResetPasswordSuccess : actions.msgResetPasswordSuccess
      };
    case types.CHANGE_SELFIE_USER:
      return {
          ...state,
          user : {...state.user, avatar: actions.avatar } 
      };
    case types.CHANGE_EMPRESA:
      return {
        ...state,
        user: { ...state.user, company: actions.company }
      };
    case types.CHANGE_NOTIFICACOES:
      return {
        ...state,
        user: { ...state.user, messages: actions.messages }
      };
    case types.CHANGE_LAST_ACCESS:
      return {
        ...state,
        user: { ...state.user, firstAccess: actions.firstAccess }
      };
      

    default:
      return state;
  }
}
