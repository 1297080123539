//import axios from "../utils/axios";
import Axios from "axios";

function buscarNotificacao(titulo, visualizada, page = 0) {
  return new Promise((resolve, reject) => {
    const vo = {
      tipo: 'SISTEMA',
      titulo: titulo,
      visualizada: visualizada
    };
    Axios
      .post(`/notificacao/pesquisa/${page}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function pesquisaEnviados(titulo, visualizada, page = 0) {
  return new Promise((resolve, reject) => {
    const vo = {
      titulo: titulo,
      visualizada: visualizada
    };
    Axios
      .post(`/notificacao/pesquisaEnviados/${page}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const fileToBase64 = (filename, filepath) => {
  return new Promise(resolve => {
    var file = new File([filename], filepath);
    var reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = function (event) {
      resolve(event.target.result);
    };
    // Convert data to base64 
    reader.readAsDataURL(file);
  });
};

function download(caminho) {
  return new Promise((resolve, reject) => {
    Axios
      .get(`/notificacao/downloadFile/${caminho}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


function createNotificacao({ titulo, texto, tipo, permiteRespostas, notificacaoReferencia, pessoas, anexos, publicoAlvo }) {

  return new Promise((resolve, reject) => {
    const vo = {
      titulo,
      texto,
      tipo,
      permiteRespostas,
      notificacaoReferencia,
      pessoas,
      anexos,
      publicoAlvo
    };
    Axios
      .post(`/notificacao`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function deleteNotificacao(idNotificacao) {
  return new Promise((resolve, reject) => {
    Axios
      .delete(`/notificacao/${idNotificacao}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


function marcarComoLida(idNotificacao) {
  return new Promise((resolve, reject) => {
    Axios
      .put(`/notificacao/visualizar/${idNotificacao}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarLeituras(idNotificacao, pessoaLeitura, dataInclusao,  page = 0) {
  return new Promise((resolve, reject) => {

    const vo = {
      pessoaLeitura,
      dataInclusao
    }

    Axios
      .post(`/notificacao/buscarVisualizacoes/${idNotificacao}/${page}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


const NotificacaoService = {
  deleteNotificacao,
  createNotificacao,
  buscarNotificacao,
  pesquisaEnviados,
  marcarComoLida,
  download,
  buscarLeituras

}


export default NotificacaoService
