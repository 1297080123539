import { Button } from "@material-ui/core";
import React from "react";
import { Save } from "react-feather";

const SalvarButton = ({ onClick = () => {}, title = "Salvar" }) => {
  return (
    <>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        startIcon={<Save />}
        onClick={() => onClick()}
      >
        {title}
      </Button>
    </>
  );
};

export default SalvarButton;
