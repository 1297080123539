import {
    Box,
    Card as MuiCard,
    CardContent,
    MenuItem,
    Paper,
    Typography
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Formik } from 'formik';
import React from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import TextField from "../../components/icarus/textfield/TextField";
import TextFieldCEP from "../../components/icarus/textfield/TextFieldCEP";
import Loading from "../../components/Loading";
import { ESTADOS_JSON, MESSAGE_VALIDATION_INPUT } from '../../constants';
import UtilAPI from '../../services/UtilAPI';
import MaskUtil from "../../utils/MaskUtil";
import AlterarButton from "./buttons/AlterarButton";
import SelectMenu from "./select/SelectMenu";


const Card = styled(MuiCard)(spacing);

const validationSchemaEndereco = Yup.object().shape({
    cep: Yup.number().positive().required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD).nullable(),
    endereco: Yup.string().min(1).max(255).required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD).nullable(),
    numero: Yup.string().min(1).max(255).nullable(),
    bairro: Yup.string().min(1).max(255).required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD).nullable(),
    estado: Yup.string().min(1).max(255).required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD).nullable(),
    cidade: Yup.string().min(1).max(255).required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD).nullable(),
    complemento: Yup.string().min(1).max(30).nullable(),
});

function FormEndereco({ carregandoEndereco, salvando, endereco, setEndereco, update}) {

    const buscarCEP = (cep) => {

        UtilAPI.buscarCep(cep).then((response) => {

            const result = response.data;
            const buscaEndereco = { cep, endereco: result.logradouro, bairro: result.bairro, cidade: result.localidade, estado: result.uf, complemento: result.complemento };
            setEndereco(buscaEndereco);

        }).catch((error) => console.log(error));
    };

    return (
        <Paper elevation={4}>
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Endereço
                </Typography>
                {
                    carregandoEndereco ? <Loading /> :

                        <Formik
                            enableReinitialize
                            initialValues={{
                                cep: !endereco ? undefined : endereco.cep,
                                endereco: !endereco ? undefined : endereco.endereco,
                                numero: !endereco ? undefined : endereco.numero,
                                bairro: !endereco ? undefined : endereco.bairro,
                                estado: !endereco ? undefined : endereco.estado,
                                cidade: !endereco ? undefined : endereco.cidade,
                                complemento: !endereco ? undefined : endereco.complemento
                            }}
                            validationSchema={validationSchemaEndereco}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                update(values);
                            }}>
                            {(formik) => (
                                <form noValidate onSubmit={formik.handleSubmit}>
                                    


                                    <TextFieldCEP
                                        id="cep"
                                        label="CEP"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        my={2}
                                        style={{ marginTop: 15 }}
                                        onBlur={
                                            (e) => {
                                                buscarCEP(MaskUtil.undoMask(e.target.value));
                                                formik.handleBlur(e)
                                            }
                                        }
                                        error={Boolean(formik.touched.cep && formik.errors.cep)}
                                        helperText={formik.touched.cep && formik.errors.cep}
                                        value={formik.values.cep}
                                        onChange={(ret) => {
                                            formik.setFieldValue('cep', ret, true);
                                        }}
                                    />

                                    <TextField
                                        id="endereco"
                                        label="Endereço"
                                        variant="outlined"
                                        fullWidth
                                        my={2}
                                        required
                                        style={{ marginTop: 15 }}
                                        error={Boolean(formik.touched.endereco && formik.errors.endereco)}
                                        helperText={formik.touched.endereco && formik.errors.endereco}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.endereco}
                                    />

                                    <TextField
                                        id="numero"
                                        label="Número"
                                        variant="outlined"
                                        fullWidth
                                        my={2}
                                        required
                                        style={{ marginTop: 15 }}
                                        error={Boolean(formik.touched.numero && formik.errors.numero)}
                                        helperText={formik.touched.numero && formik.errors.numero}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.numero}
                                    />


                                    <TextField
                                        id="complemento"
                                        label="Complemento"
                                        variant="outlined"
                                        fullWidth
                                        my={2}
                                        style={{ marginTop: 15 }}
                                        error={Boolean(formik.touched.complemento && formik.errors.complemento)}
                                        helperText={formik.touched.complemento && formik.errors.complemento}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.complemento}
                                    />

                                    <TextField
                                        id="bairro"
                                        label="Bairro"
                                        variant="outlined"
                                        fullWidth
                                        my={2}
                                        required
                                        style={{ marginTop: 15 }}
                                        error={Boolean(formik.touched.bairro && formik.errors.bairro)}
                                        helperText={formik.touched.bairro && formik.errors.bairro}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.bairro}
                                    />
                                    <Box my={5} />

                                    <TextField
                                        id="cidade"
                                        label="Cidade"
                                        variant="outlined"
                                        fullWidth
                                        my={2}
                                        required
                                        error={Boolean(formik.touched.cidade && formik.errors.cidade)}
                                        helperText={formik.touched.cidade && formik.errors.cidade}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.cidade}
                                    />
                                    <Box my={5} />
                                    <SelectMenu
                                        label="Estado"
                                        id="estado"
                                        required
                                        
                                        error={Boolean(formik.touched.estado && formik.errors.estado)}
                                        helperText={formik.touched.estado && formik.errors.estado}
                                        onBlur={formik.handleBlur}
                                        onChange={(e) => {
                                            formik.setFieldValue('estado', e.target.value, true)
                                        }}
                                        value={formik.values.estado}>
                                            <MenuItem value={undefined}>Selecione</MenuItem>
                                        {ESTADOS_JSON.map((item) => (
                                                <MenuItem value={item.sigla} selected={item.sigla === formik.values.estado}>{item.nome}</MenuItem>
                                            ))}
                                    </SelectMenu>

                                    <div style={{ marginTop: 15 }}>
                                        {salvando ? <Loading /> :
                                            <AlterarButton />
                                        }
                                    </div>

                                </form>
                            )}
                        </Formik>
                }
            </CardContent>
            </Card>
            </Paper>
    );
}

export default FormEndereco;
