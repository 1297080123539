import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@material-ui/core";
import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { spacing } from "@material-ui/system";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import * as Yup from "yup";
import Alert from "../../../components/alert/Alert";
import AlterarButton from "../../../components/icarus/buttons/AlterarButton";
import TextField from "../../../components/icarus/textfield/TextField";
import TextFieldCNPJ from "../../../components/icarus/textfield/TextFieldCNPJ";
import TextFieldTelefone from "../../../components/icarus/textfield/TextFieldTelefone";
import Loading from "../../../components/Loading";
import * as types from "../../../constants";
import { MESSAGE_VALIDATION_INPUT } from "../../../constants";
import EmpresaService from "../../../services/EmpresaService";

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const validationSchema = Yup.object().shape({
  nome: Yup.string()
    .min(5)
    .max(255)
    .required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD)
    .nullable(),
  razaoSocial: Yup.string()
    .min(5)
    .max(255)
    .required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD)
    .nullable(),
  email: Yup.array()
    .required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD)
    .min(1, "Informe no minímo um email")
    .max(10, "Permitido no máximo 10 emails")
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      let returnedValue = originalValue ? originalValue.split(/[\s,]+/) : [];
      return returnedValue;
    })
    .of(
      Yup.string()
        .required("Informe no mínimo um email")
        .email(({ value }) => `Informe um email válido para ${value}.`)
    ),
  telefone: Yup.string()
    .required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD)
    .nullable(),
  documento: Yup.string()
    .min(5)
    .max(255)
    .required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD)
    .nullable(),
});

function DadosEmpresa({ onSave = () => {} }) {
  const [empresa, setEmpresa] = useState(null);
  const [carregando, setCarregando] = useState(true);
  const [salvando, setSalvando] = useState(false);
  const [salvandoLogomarca, setSalvandoLogomarca] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    carregarDados();
  }, []);

  const uploadLogomarca = (response) => {
    setSalvandoLogomarca(true);
    EmpresaService.logomarca(response.target.files[0])
      .then((ret) => {
        if (ret) {
          dispatch({
            type: types.CHANGE_EMPRESA,
            company: ret.data,
          });
        }
        Alert.success("Sucesso", "Logomarca alterada com sucesso");
      })
      .finally(() => setSalvandoLogomarca(false));
  };

  const carregarDados = () => {
    setCarregando(true);
    EmpresaService.buscar()
      .then((response) => {
        setEmpresa(response);
      })
      .finally(() => setCarregando(false));
  };

  const update = (values) => {
    setSalvando(true);

    let emails = "";

    values.email.map((item) => {
      emails = emails + item + ";";
    });

    EmpresaService.updateEmpresaUsuarioLogado({
      nome: values.nome,
      razaoSocial: values.razaoSocial,
      documento: values.documento,
      email: emails,
      telefone: values.telefone,
    })
      .then((response) => {
        dispatch({
          type: types.CHANGE_EMPRESA,
          company: response,
        });
        carregarDados();
        onSave();
        Alert.success("Sucesso", "Dados alterados com sucesso");
      })
      .finally(() => {
        setSalvando(false);
      });
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Informações da Empresa
        </Typography>

        {carregando ? (
          <Loading />
        ) : (
          <Formik
            initialValues={{
              nome: !empresa ? undefined : empresa.nome,
              razaoSocial: !empresa ? undefined : empresa.razaoSocial,
              documento: !empresa ? undefined : empresa.documento,
              email:
                !empresa || !empresa.email
                  ? undefined
                  : empresa.email.split(";"),
              telefone: !empresa ? undefined : empresa.telefone,
            }}
            validationSchema={validationSchema}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              setCarregando(true);
              update(values);
            }}
          >
            {(formik) => (
              <form noValidate onSubmit={formik.handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item md={8}>
                    <TextField
                      id="nome"
                      label="Nome Fantasia"
                      variant="outlined"
                      style={{ marginTop: 15 }}
                      fullWidth
                      required
                      my={2}
                      error={Boolean(formik.touched.nome && formik.errors.nome)}
                      helperText={formik.touched.nome && formik.errors.nome}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.nome}
                    />

                    <TextField
                      id="razaoSocial"
                      label="Razão Social"
                      variant="outlined"
                      style={{ marginTop: 15 }}
                      fullWidth
                      required
                      my={2}
                      error={Boolean(
                        formik.touched.razaoSocial && formik.errors.razaoSocial
                      )}
                      helperText={
                        formik.touched.razaoSocial && formik.errors.razaoSocial
                      }
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.razaoSocial}
                    />

                    <TextFieldCNPJ
                      id="documento"
                      label="CNPJ"
                      variant="outlined"
                      required
                      style={{ marginTop: 15 }}
                      fullWidth
                      my={2}
                      error={Boolean(
                        formik.touched.documento && formik.errors.documento
                      )}
                      helperText={
                        formik.touched.documento && formik.errors.documento
                      }
                      onBlur={formik.handleBlur}
                      onChange={(e) => formik.setFieldValue("documento", e)}
                      value={formik.values.documento}
                    />

                    <Autocomplete
                      multiple
                      id="tags-filled"
                      options={[]}
                      freeSolo
                      style={{ marginTop: 15 }}
                      value={formik.values.email}
                      onChange={(event, value, reason, details) => {
                        formik.setFieldValue("email", value);
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          fullWidth
                          variant="outlined"
                          error={Boolean(
                            formik.touched.email && formik.errors.email
                          )}
                          onBlur={formik.handleBlur}
                          helperText={
                            !formik.touched.email && !formik.errors.email
                              ? "Para adicionar um novo aperte enter"
                              : formik.touched.email && formik.errors.email
                          }
                          label="Email de Faturamento"
                        />
                      )}
                    />

                    <TextFieldTelefone
                      id="telefone"
                      label="Telefone"
                      variant="outlined"
                      style={{ marginTop: 15 }}
                      required
                      fullWidth
                      my={2}
                      value={formik.values.telefone}
                      error={Boolean(
                        formik.touched.telefone && formik.errors.telefone
                      )}
                      helperText={
                        formik.touched.telefone && formik.errors.telefone
                      }
                      onBlur={formik.handleBlur}
                      onChange={(ret) => {
                        formik.setFieldValue("telefone", ret, true);
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    {salvandoLogomarca ? (
                      <Loading />
                    ) : (
                      <CenteredContent>
                        <Box ml={1}>
                          <img
                            alt="logomarca"
                            width={190}
                            height={70}
                            src={user.company.logomarca}
                          />
                        </Box>

                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="raised-button-file"
                          type="file"
                          onChange={(value) => uploadLogomarca(value)}
                        />
                        <label htmlFor="raised-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            <CloudUpload mr={2} /> Upload
                          </Button>

                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            Para obter os melhores resultados, utilize uma
                            imagem de boa resolução
                          </Typography>
                        </label>
                      </CenteredContent>
                    )}
                  </Grid>
                </Grid>
                <Box my={8} />
                {salvando ? <Loading /> : <AlterarButton />}
              </form>
            )}
          </Formik>
        )}
      </CardContent>
    </Card>
  );
}

export default DadosEmpresa;
