//import axios from "../utils/axios";
import Axios from "axios";

function buscarPorIdPessoa(idPessoa) {
  return new Promise((resolve, reject) => {

    Axios
      .get(`/usuario/buscarPorIdPessoa/${idPessoa}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


function newUser(pessoa, senha, perfil) {
  return new Promise((resolve, reject) => {

    const vo = {
      pessoa,
      perfil,
      passwordUsuario: senha
    }

    Axios
      .post(`/usuario`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function alterarPerfil(idUsuario, perfil) {
  
  return new Promise((resolve, reject) => {
  
    Axios
      .put(`/usuario/${idUsuario}`, perfil)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function alterarSenha(idUsuario, senha) {
  return new Promise((resolve, reject) => {

    Axios
      .post(`/usuario/alterarSenha/${idUsuario}`, senha)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


function alterarStatus(idUsuario, status) {
  return new Promise((resolve, reject) => {

    Axios
      .post(`/usuario/alterarStatus/${idUsuario}`, status)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function alterarVigencia(idUsuario, vigencia) {
  return new Promise((resolve, reject) => {

    Axios
      .post(`/usuario/alterarVigencia/${idUsuario}`, vigencia)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}



function recuperarSenha(object) {
  return new Promise((resolve, reject) => {

    Axios
      .post(`/usuario/recuperarSenha`, JSON.stringify(object))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


function buscarCodigoRecuperacaoSenha(hash) {
  return new Promise((resolve, reject) => {

    Axios
      .get(`/usuario/buscarCodigoRecuperacaoSenha/${hash}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function alterarSenhaUserLogado(newPassword, oldPassword) {
  return new Promise((resolve, reject) => {

    const vo = {
      newPassword,
      oldPassword
    }

    Axios
      .post(`/usuario/alterarSenha`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


function removerRestricaoExcessoErrosSenha(idUsuario) {
  return new Promise((resolve, reject) => {

    Axios
      .get(`/usuario/removerRestricaoExcessoErrosSenha/${idUsuario}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


function changeCompany(idEmpresa) {
  return new Promise((resolve, reject) => {

    Axios
      .get(`/usuario/mudarEmpresa/${idEmpresa}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


const UsuarioService = {
  buscarPorIdPessoa,
  changeCompany,
  newUser,
  alterarStatus,
  alterarSenha,
  alterarSenhaUserLogado,
  alterarVigencia,
  alterarPerfil,
  buscarCodigoRecuperacaoSenha,
  recuperarSenha,
  removerRestricaoExcessoErrosSenha
}



export default UsuarioService
