//import axios from "../utils/axios";
import Axios from "axios";

export function signIn(credentials) {
  return  Axios.post("/usuario/logar", credentials);
}

export function validaToken(token) {

  const instance = Axios.create({
    timeout: 10000
  });
  
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  instance.defaults.headers.post['Content-Type'] = 'application/json; charset=utf8';
  
  return instance.get("/usuario/validarToken");
}

export function resetPassword(username) {
  return new Promise((resolve, reject) => {
    Axios
    .get(`/usuario/recuperarSenha/${username}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
