import {
    Box,
    Button as MuiButton,
    Card as MuiCard,
    CardContent, FormControlLabel,
    Grid, Switch,
    TextField,
    Typography
} from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { Formik } from "formik";
import JoditEditor from "jodit-react";
import React, { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import * as Yup from "yup";
import Alert from "../../../components/alert/Alert";
import SalvarButton from "../../../components/icarus/buttons/SalvarButton";
import TextFieldTelefone from "../../../components/icarus/textfield/TextFieldTelefone";
import Loading from "../../../components/Loading";
import BrandingService from "../../../services/BrandingService";
import PermissionUtil from "../../../utils/PermissionUtil";

const CenteredContent = styled.div`
  text-align: center;
`;

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

export default function BrandingConsignado() {

    const [carregando, setCarregando] = useState(false);
    const [tempLogo, setTempLogo] = useState();
    const [tempBaner, setTempBaner] = useState();
    const [branding, setBranding] = useState();
    const [carregandoDados, setCarregandoDados] = useState(false);
    const { user } = useSelector((state) => state.authReducer);

    const editor = useRef(null);
    const editor2 = useRef(null);
    const editor3 = useRef(null);

    const editorHeader = useRef(null);
    const editorHeader2 = useRef(null);

    useEffect(() => {


        if (PermissionUtil.hasPermission("manter_branding", user)) {
            setCarregandoDados(true);
            BrandingService.buscar('CONSIGNADO')
                .then((ret) => {
                    setBranding(ret);
                })
                .finally(() => setCarregandoDados(false));
        }

    }, []);

    const salvar = (values) => {
        setCarregando(true);

        const {
            logomarca,
            baner,
            cor,
            url,
            urlTermos,
            header,
            subheader,
            whatsapp,
            header2,
            subheader2,
            scripts,
            rodape,
            exibirFormularioHeader,
        } = values;

        BrandingService.create({
            logomarca,
            baner,
            cor,
            url,
            bancos: [],
            urlTermos,
            header,
            subheader,
            whatsapp,
            header2,
            subheader2,
            scripts,
            rodape,
            exibirFormularioHeader,
            tipoBranding: 'CONSIGNADO'
        })
            .then(() => {
                Alert.success("", "Salvo com sucesso!");
            })
            .finally(() => setCarregando(false));
    };

    return (
        carregandoDados ? <Loading /> :
            <Formik
                initialValues={{
                    logomarca: null,
                    baner: null,
                    urlTermos: branding && branding.urlTermos ? branding.urlTermos : null,
                    logomarcaUrl: branding && branding.logomarca ? branding.logomarca : null,
                    banerUrl: branding && branding.baner ? branding.baner : null,
                    header: branding && branding.header ? branding.header : null,
                    subheader: branding && branding.subheader ? branding.subheader : null,
                    header2: branding && branding.header2 ? branding.header2 : null,
                    subheader2: branding && branding.subheader2 ? branding.subheader2 : null,
                    cor: branding && branding.cor ? branding.cor : "#0000CD",
                    url: branding && branding.url ? branding.url : null,
                    whatsapp: branding && branding.whatsapp ? branding.whatsapp : null,
                    scripts: branding && branding.scripts ? branding.scripts : null,
                    rodape: branding && branding.rodape ? branding.rodape : null,
                    exibirFormularioHeader: branding && branding.exibirFormularioHeader ? branding.exibirFormularioHeader : false,
                    submit: false,
                }}
                validationSchema={Yup.object().shape({
                    logomarca: Yup.object().nullable(),
                    baner: Yup.object().nullable(),
                    urlTermos: Yup.string().required().nullable().min(3).max(255),
                    cor: Yup.string().nullable().min(3).max(10),
                    url: Yup.string().required().nullable().min(3).max(255),
                    whatsapp: Yup.string().nullable().min(3).max(255),
                    header: Yup.string().required().nullable().min(3).max(60000),
                    subheader: Yup.string().required().nullable().min(3).max(60000),
                    header2: Yup.string().required().nullable().min(3).max(60000),
                    subheader2: Yup.string().required().nullable().min(3).max(60000),
                    scripts: Yup.string().nullable().min(3).max(60000),
                    rodape: Yup.string().nullable().min(3).max(60000),
                    exibirFormularioHeader: Yup.boolean().required().nullable(),
                })}

                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    salvar(values);
                }}
            >
                {(formik) => (
                    <form noValidate onSubmit={formik.handleSubmit}>
                        {carregandoDados ? <Loading /> :
                            <Card mb={6}>
                                <CardContent>
                                    <Grid container spacing={5}>
                                        <Grid item md={6}>
                                            <CenteredContent>
                                                <Box ml={1}>
                                                    {!tempLogo && formik.values.logomarcaUrl && (
                                                        <img
                                                            alt="logomarcaBranding"
                                                            width={190}
                                                            height={70}
                                                            src={formik.values.logomarcaUrl}
                                                        />
                                                    )}

                                                    {tempLogo && (
                                                        <img
                                                            alt="logomarcaBranding"
                                                            width={190}
                                                            height={70}
                                                            src={tempLogo}
                                                        />
                                                    )}
                                                </Box>

                                                <input
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                    id="raised-button-file-logomarcaBranding"
                                                    type="file"
                                                    onChange={(value) => {
                                                        formik.setFieldValue(
                                                            "logomarca",
                                                            value.target.files[0]
                                                        );
                                                        setTempLogo(
                                                            URL.createObjectURL(value.target.files[0])
                                                        );
                                                    }}
                                                />
                                                <label htmlFor="raised-button-file-logomarcaBranding">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        component="span"
                                                    >
                                                        <CloudUpload mr={2} /> Upload
                                                    </Button>

                                                    <Typography
                                                        variant="caption"
                                                        display="block"
                                                        gutterBottom
                                                    >
                                                        Coloque a logomarca que aparecerá na Landing Page
                                                        aqui. Recomendado: 755px x 200px
                                                    </Typography>
                                                </label>
                                            </CenteredContent>
                                        </Grid>

                                        <Grid item md={6}>
                                            <CenteredContent>
                                                <Box ml={1}>
                                                    {!tempBaner && formik.values.banerUrl && (
                                                        <img
                                                            alt="baner"
                                                            width={190}
                                                            height={70}
                                                            src={formik.values.banerUrl}
                                                        />
                                                    )}

                                                    {tempBaner && (
                                                        <img
                                                            alt="baner"
                                                            width={190}
                                                            height={70}
                                                            src={tempBaner}
                                                        />
                                                    )}
                                                </Box>

                                                <input
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                    id="raised-button-file-baner"
                                                    type="file"
                                                    onChange={(value) => {
                                                        formik.setFieldValue("baner", value.target.files[0]);
                                                        setTempBaner(
                                                            URL.createObjectURL(value.target.files[0])
                                                        );
                                                    }}
                                                />
                                                <label htmlFor="raised-button-file-baner">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        component="span"
                                                    >
                                                        <CloudUpload mr={2} /> Upload
                                                    </Button>

                                                    <Typography
                                                        variant="caption"
                                                        display="block"
                                                        gutterBottom
                                                    >
                                                        Coloque o baner que aparecerá na Landing Page aqui.
                                                        Recomendado: 690px x 550px
                                                    </Typography>
                                                </label>
                                            </CenteredContent>
                                        </Grid>

                                        <Grid item md={12}>
                                            <JoditEditor
                                                ref={editorHeader}
                                                tabIndex={1}
                                                onChange={(e) => {
                                                    formik.setFieldValue("header", e);
                                                }}
                                                value={formik.values.header}
                                            />
                                        </Grid>

                                        <Grid item md={12}>
                                            <Typography variant="body1">Subheader:</Typography>
                                            <JoditEditor
                                                ref={editor}
                                                tabIndex={1}
                                                onChange={(e) => {
                                                    formik.setFieldValue("subheader", e);
                                                }}
                                                value={formik.values.subheader}
                                            />
                                        </Grid>


                                        <Grid item md={12}>
                                            <JoditEditor
                                                ref={editorHeader2}
                                                tabIndex={1}
                                                onChange={(e) => {
                                                    formik.setFieldValue("header2", e);
                                                }}
                                                value={formik.values.header2}
                                            />
                                        </Grid>

                                        <Grid item md={12}>
                                            <Typography variant="body1">Subheader 2:</Typography>
                                            <JoditEditor
                                                ref={editor2}
                                                tabIndex={1}
                                                onChange={(e) => {
                                                    formik.setFieldValue("subheader2", e);
                                                }}
                                                value={formik.values.subheader2}
                                            />
                                        </Grid>

                                        <Grid item md={12}>
                                            <Typography variant="body1">Rodapé:</Typography>
                                            <JoditEditor
                                                ref={editor3}
                                                tabIndex={1}
                                                onChange={(e) => {
                                                    formik.setFieldValue("rodape", e);
                                                }}
                                                value={formik.values.rodape}
                                            />
                                        </Grid>

                                        <FormControlLabel
                                            control={<Switch checked={formik.values.exibirFormularioHeader}
                                                onChange={(e) => formik.setFieldValue('exibirFormularioHeader', e.target.checked)} name="exibirFormularioHeader" />}
                                            label="Exibir Formulário no Topo da Página"
                                        />

                                        <Grid item md={12}>
                                            <TextField
                                                id="url"
                                                label="URL da Landing Page"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                my={2}
                                                error={Boolean(formik.touched.url && formik.errors.url)}
                                                helperText={formik.touched.url && formik.errors.url}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.url}
                                            />
                                        </Grid>

                                        <Grid item md={12}>
                                            <TextFieldTelefone
                                                id="whatsapp"
                                                label="Whatsapp"
                                                variant="outlined"
                                                fullWidth
                                                my={2}
                                                error={Boolean(
                                                    formik.touched.whatsapp && formik.errors.whatsapp
                                                )}
                                                helperText={
                                                    formik.touched.whatsapp && formik.errors.whatsapp
                                                }
                                                onBlur={formik.handleBlur}
                                                onChange={(e) => {
                                                    formik.setFieldValue("whatsapp", e);
                                                }}
                                                value={formik.values.whatsapp}
                                            />
                                        </Grid>

                                        <Grid item md={12}>
                                            <TextField
                                                id="urlTermos"
                                                label="URL dos Termos de Uso"
                                                variant="outlined"
                                                fullWidth
                                                required
                                                my={2}
                                                error={Boolean(
                                                    formik.touched.urlTermos && formik.errors.urlTermos
                                                )}
                                                helperText={
                                                    formik.touched.urlTermos && formik.errors.urlTermos
                                                }
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.urlTermos}
                                            />
                                        </Grid>

                                        <Grid item md={12}>
                                            <TextField
                                                id="scripts"
                                                label="GTM - Google Tag Manager"
                                                variant="outlined"
                                                fullWidth
                                                my={2}
                                                error={Boolean(
                                                    formik.touched.scripts && formik.errors.scripts
                                                )}
                                                helperText={
                                                    formik.touched.scripts && formik.errors.scripts
                                                }
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.scripts}
                                            />
                                        </Grid>


                                        <Grid item md={12}>
                                            <Typography display="block" gutterBottom>
                                                Cor da Landing Page:
                                            </Typography>
                                            <SketchPicker
                                                color={formik.values.cor}
                                                onChange={(value) => {
                                                    formik.setFieldValue("cor", value.hex);
                                                }}
                                            />
                                        </Grid>

                                        <Grid item>
                                            {carregando ? <Loading /> : <SalvarButton />}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        }
                    </form>
                )}
            </Formik>

    );
}