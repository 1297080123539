import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@material-ui/core";
import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { Formik } from "formik";
import JoditEditor from "jodit-react";
import { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import styled from "styled-components/macro";
import * as Yup from "yup";
import Alert from "../../components/alert/Alert";
import Dialog from "../../components/Dialog";
import AlterarButton from "../../components/icarus/buttons/AlterarButton";
import CadastrarButton from "../../components/icarus/buttons/CadastrarButton";
import TextField from "../../components/icarus/textfield/TextField";
import TextFieldTelefone from "../../components/icarus/textfield/TextFieldTelefone";
import Loading from "../../components/Loading";
import { MESSAGE_VALIDATION_INPUT } from "../../constants";
import BancoService from "../../services/BancoService";
import EmpresaService from "../../services/EmpresaService";

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CenteredContent = styled.div`
  text-align: center;
`;

const validationSchema = Yup.object().shape({
  nomeEmpresa: Yup.string()
    .min(5)
    .max(255)
    .required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD),
  email: Yup.string().email().required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD),
  telefone: Yup.string().required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD),
  senha: Yup.string()
    .min(5)
    .max(255)
    .required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD)
});

function Public({ close }) {
  const [salvando, setSalvando] = useState(false);
  const [bancos, setBancos] = useState([]);
  const [tempLogo, setTempLogo] = useState();
  const [tempBaner, setTempBaner] = useState();

  const editor = useRef(null);
  const editor2 = useRef(null);

  useEffect(() => {
    BancoService.buscarBancosHabilitados().then((ret) => {
      setBancos(ret);
    });
  }, []);

  const updateEmpresa = (values) => {
    setSalvando(true);

    const {
      email,
      telefone,
      nomeEmpresa,
      nomeContato,
      senha,
    } = values;

    EmpresaService.trial({
      email,
      telefone,
      nomeEmpresa,
      nomeContato,
      senha,
    })
      .then(() => {
        Alert.success("Sucesso", "Empresa criada com sucesso");
        close();
      })
      .finally(() => {
        setSalvando(false);
      });
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Informações da Empresa
        </Typography>

        <Formik
          initialValues={{
            nomeEmpresa: "",
            nomeContato: "",
            email: "",
            telefone: "",
            senha: "",
          }}
          validationSchema={validationSchema}
          validate={(values, props) => {
            return null;
          }}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            updateEmpresa(values);
          }}
        >
          {(formik) => (
            <form noValidate onSubmit={formik.handleSubmit}>


              <Grid container spacing={5}>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    id="nomeEmpresa"
                    label="Nome Fantasia"
                    variant="outlined"
                    fullWidth
                    required
                    my={2}
                    error={Boolean(
                      formik.touched.nomeEmpresa && formik.errors.nomeEmpresa
                    )}
                    helperText={
                      formik.touched.nomeEmpresa && formik.errors.nomeEmpresa
                    }
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.nomeEmpresa}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    id="nomeContato"
                    label="Nome do Contato"
                    variant="outlined"
                    fullWidth
                    required
                    my={2}
                    error={Boolean(
                      formik.touched.nomeContato && formik.errors.nomeContato
                    )}
                    helperText={
                      formik.touched.nomeContato && formik.errors.nomeContato
                    }
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.nomeContato}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                    my={2}
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                  <TextFieldTelefone
                    id="telefone"
                    label="Telefone"
                    variant="outlined"
                    fullWidth
                    required
                    my={2}
                    value={formik.values.telefone}
                    error={Boolean(
                      formik.touched.telefone && formik.errors.telefone
                    )}
                    helperText={
                      formik.touched.telefone && formik.errors.telefone
                    }
                    onBlur={formik.handleBlur}
                    onChange={(ret) => {
                      formik.setFieldValue("telefone", ret, true);
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    id="senha"
                    label="Senha"
                    variant="outlined"
                    fullWidth
                    required
                    my={2}
                    error={Boolean(formik.touched.senha && formik.errors.senha)}
                    helperText={formik.touched.senha && formik.errors.senha}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.senha}
                  />
                </Grid>
              </Grid>
              <Box my={8} />
              {salvando ? <Loading /> : <CadastrarButton />}
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
}

function CadastrarEmpresa({ show = false, onRequestClose }) {
  const [showDialog, setShowDialog] = useState(show);

  const handleClose = () => {
    setShowDialog(false);
    onRequestClose();
  };

  return (
    <Dialog
      title={`Cadastrar Empresa`}
      show={showDialog}
      maxWidth={"lg"}
      onRequestClose={() => handleClose()}
    >
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Public close={handleClose} />
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default CadastrarEmpresa;
