import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  Tooltip,
  Typography
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import { MessageSquare } from "react-feather";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import NotificacaoService from "../services/NotificacaoService";
import { useDispatch } from "react-redux";
import * as types from "../constants"; 
import DialogNotificacao from "../pages/cadastro/notificacao/DialogNotificacao";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const MessageHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Message({ item }) {
  const [showDialog, setShowDialog] = useState(false);

  const dispatch = useDispatch();

  const visualizar = () => {
    
    setShowDialog(true);
    
  }

  const marcarVisualizada = () => {
    NotificacaoService.marcarComoLida(item.idNotificacao)
      .then((retorno) => {
        dispatch({
          type: types.CHANGE_NOTIFICACOES,
          messages: retorno
        });
      });
  }

  return (
    <>
      {showDialog && <DialogNotificacao show={showDialog} onRequestClose={() => {
        setShowDialog(false);
        marcarVisualizada();
      }} data={item} />}
      <ListItem divider component={Link} to="#" onClick={() => visualizar(item)}   >
      <ListItemAvatar>
        <Avatar src={item.usuarioInclusao.pessoa.caminhoFoto} alt="Avatar" />
      </ListItemAvatar>
      <ListItemText
          primary={item.tituloCurto}
          primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
          secondary={item.textoCurto}
      />
      </ListItem>
      </>
  );
}

function MessagesDropdown() {
  const ref = useRef(null);
  const { user } = useSelector(state => state.authReducer);
  const [isOpen, setOpen] = useState(false);
  

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      
      {user.messages &&
      <Tooltip title="Mensagens">
        <IconButton color="inherit" ref={ref} onClick={handleOpen}>
          <Indicator badgeContent={user.messages.totalElements}>
            <MessageSquare />
          </Indicator>
        </IconButton>         
      </Tooltip>
      }
      {user.messages &&
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          anchorEl={ref.current}
          onClose={handleClose}
          open={isOpen}
        >
          <MessageHeader p={2}>
            <Typography variant="subtitle1" color="textPrimary">
            {user.messages.totalElements} Novas Mensagens
          </Typography>
          </MessageHeader>
          <React.Fragment>
            <List disablePadding>

            {user.messages.content.map((item) => {
              return <Message
                  item={item}
                />

              })}

            </List>
            <Box p={1} display="flex" justifyContent="center">
            <Button size="small" component={Link} to="/notificacao/manter">
                Exibir todas as mensagens
            </Button>
            </Box>
          </React.Fragment>
        </Popover>
      }
    </React.Fragment>
  );
}

export default MessagesDropdown;
