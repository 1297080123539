import { Box, CircularProgress, Grid, MenuItem, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import React, { useState } from 'react';
import FileBase64 from 'react-file-base64';
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";
import { Link } from 'react-router-dom';
import * as Yup from "yup";
import Alert from '../../../components/alert/Alert';
import Dialog from '../../../components/Dialog';
import FieldSet from '../../../components/FieldSet';
import CadastrarButton from '../../../components/icarus/buttons/CadastrarButton';
import CancelarButton from '../../../components/icarus/buttons/CancelarButton';
import PessoaAutoComplete from '../../../components/icarus/PessoaAutoComplete';
import SelectMenu from '../../../components/icarus/select/SelectMenu';
import TextArea from '../../../components/icarus/textfield/TextArea'; 
import TextField from "../../../components/icarus/textfield/TextField";
import Loading from '../../../components/Loading';
import { DOMINIO_PUBLICO_ALVO_MENSAGEM, DOMINIO_TIPO_NOTIFICACAO, MESSAGE_VALIDATION_INPUT } from '../../../constants';
import NotificacaoService from '../../../services/NotificacaoService';
import DateUtil from '../../../utils/DateUtil';
 
const validationSchema = Yup.object().shape({
    titulo: Yup.string().required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD).min(5).max(255),
    texto: Yup.string().required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD).min(5).max(65535),
    tipo: Yup.string().min(1).max(255).required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD),
    notificacaoReferencia: Yup.object().nullable(),
    pessoas: Yup.array().required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD),
    anexos: Yup.array().nullable(),
    publicoAlvo: Yup.string().min(1).max(255).required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD),
});

const dominios = Object.values(DOMINIO_TIPO_NOTIFICACAO);
const dominiosPublicoAlvo = Object.values(DOMINIO_PUBLICO_ALVO_MENSAGEM);

function Anexos({ item }) {

    const [carregando, setCarregando] = useState(false);

    const download = (selecionado) => {

        setCarregando(true);

        NotificacaoService.download(selecionado.caminho)
            .then((response) => {
                
                const linkSource = response;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = selecionado.fileName;
                downloadLink.click()

            }).finally(() => setCarregando(false));
    }
    
    return (

        carregando ? <Loading /> :

        <Grid container>
                {item && item.anexos &&
                    item.anexos.map((value) => {
                        
                        return <Grid item md="12"><Link
                                    style={{ maringLeft: 20 }}
                                onClick={() => download(value)}>{value.fileName}</Link></Grid>
                    })}
        </Grid>
            

    )

}


function DialogNotificacao({ show=false, onRequestClose, data }) {
    const [showDialog, setShowDialog] = useState(show);
    const [carregando, setCarregando] = useState(false);

    const handleClose = () => {
        setShowDialog(false);
        onRequestClose();
    }

    const createNotificacao = (values) => {
        setCarregando(true);

        const { titulo, texto, tipo, permiteRespostas, notificacaoReferencia, pessoas, anexos, publicoAlvo } = values;
        NotificacaoService.createNotificacao({ titulo, texto, tipo, permiteRespostas, notificacaoReferencia, pessoas, anexos, publicoAlvo })
        .then((response) => {
            Alert.success('', 'Sucesso ao Incluir a Notificação');
            handleClose();
        })
        .finally(() => setCarregando(false));
    }

    const renderCarregando = () => {
        return (
            <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Dialog title={`Notificação - ${!data ? 'Incluir' : 'Visualizar'}`}
            show={showDialog}
            onRequestClose={() => handleClose()}>
            
            <Formik
                initialValues={{
                    titulo: !data ? undefined : data.titulo,
                    texto: !data ? '' : data.texto,
                    publicoAlvo: 'INDIVIDUAL',
                    tipo: !data ? undefined : data.tipo,
                    notificacaoReferencia: !data ? undefined : data.notificacaoReferencia,
                    pessoas: !data ? [] : data.pessoas,
                    anexos: !data ? [] : data.anexos,                    
                    submit: false,
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    
                    createNotificacao(values);
                   
                }}>
                    {(formik) => (
                    <form noValidate onSubmit={formik.handleSubmit}>

                        <Grid container spacing={6} >
                            <Grid item md={12} sm={12} xs={12}>
                                <TextField
                                    name="titulo"
                                    label="Título"
                                    value={formik.values.titulo}
                                    fullWidth
                                    disabled={data}
                                    my={10}
                                    error={Boolean(formik.touched.titulo && formik.errors.titulo)}
                                    helperText={formik.touched.titulo && formik.errors.titulo}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    />
                            </Grid>
                            
                            <Grid item md={12} sm={12} xs={12}>
                                <SelectMenu
                                    value={formik.values.tipo}
                                    label="Meio do Envio"
                                    error={Boolean(formik.touched.tipo && formik.errors.tipo)}
                                    helperText={formik.touched.tipo && formik.errors.tipo}
                                    onBlur={formik.handleBlur}
                                    disabled={data}
                                    onChange={(e) => {
                                        formik.setFieldValue('tipo', e.target.value, true)
                                    }}
                                    my={10}>
                                    <MenuItem value={undefined}>{'Selecione'}</MenuItem>
                                    {dominios.map((item) => (
                                        <MenuItem value={item.name}>{item.desc}</MenuItem>
                                    ))}
                                </SelectMenu>
                            </Grid>
                            
                            {!data &&
                                <Grid item md={12} sm={12} xs={12}>
                                <SelectMenu
                                    value={formik.values.publicoAlvo}
                                    label="Público Alvo"
                                    error={Boolean(formik.touched.publicoAlvo && formik.errors.publicoAlvo)}
                                    helperText={formik.touched.publicoAlvo && formik.errors.publicoAlvo}
                                    onBlur={formik.handleBlur}
                                    disabled={data}
                                    onChange={(e) => {
                                        formik.setFieldValue('publicoAlvo', e.target.value, true)
                                    }}
                                    my={10}>
                                    {dominiosPublicoAlvo.map((item) => (
                                        <MenuItem value={item.name}>{item.desc}</MenuItem>
                                    ))}
                                </SelectMenu>
                            </Grid>
                            }

                                
                            {formik.values.publicoAlvo && formik.values.publicoAlvo === 'INDIVIDUAL' &&
                                <Grid item md={12} sm={12} xs={12}> 
                                <PessoaAutoComplete
                                    multiple
                                    value={formik.values.pessoas}
                                    disabled={data}
                                    onChange={(option, value) => {
                                        formik.setFieldValue('pessoas', value)
                                    }}
                                    error={Boolean(formik.touched.pessoas && formik.errors.pessoas)}
                                    helperText={formik.touched.pessoas && formik.errors.pessoas}
                                    onBlur={formik.handleBlur} />
                            </Grid>
                            }

                            {data &&
                                <Grid item md={12} sm={12} xs={12}>
                                    <TextField
                                        label="Enviado Por"
                                        fullWidth
                                        value={data.usuarioInclusao.pessoa.nome}
                                        disabled={true} />
                                </Grid>
                            }

                            {data &&
                                <Grid item md={12} sm={12} xs={12}>
                                    <TextField
                                        label="Enviado Em"
                                        fullWidth
                                        value={DateUtil.tempoFormatado(data.dataInclusao)}
                                        disabled={true} />
                                </Grid>
                            }


                            <Grid item md={12} sm={12} xs={12}> 
                                
                                {data ? 
                                    <FieldSet title="Mensagem">
                                        <Typography><div dangerouslySetInnerHTML={{ __html: formik.values.texto }} /></Typography> 
                                    </FieldSet>
                                    :
                                    <TextArea
                                        value={formik.values.texto}
                                        disabled={data}
                                        onChange={(value) => formik.setFieldValue('texto', value)}
                                    />
                                }
                            </Grid>


                            {formik.values.tipo !== 'PUSH' &&
                            <Grid item md={12} sm={12} xs={12}>

                                {data ?
                                    <Anexos item={data} />
                                     :
                                    <FileBase64
                                        multiple={true}
                                        onDone={(files) => {
                                            formik.setFieldValue('anexos', files)
                                        }} />
                                }
                            </Grid>
                            }
 
                        </Grid>
                        <Box my={2} />
                        {carregando ? renderCarregando() :
                        <Grid container spacing={2} >
                            <Grid item>
                                <CancelarButton onClick={handleClose} />
                                </Grid>
                                {!data &&
                                    <Grid item>
                                        <CadastrarButton />
                                    </Grid>                                   
                                }
                        </Grid>}
                    </form>
                )}
            </Formik>

           
        </Dialog>
    )
}

export default DialogNotificacao
