import { Button } from '@material-ui/core';
import React from 'react';
import { Search } from 'react-feather';

const PesquisarButton = ({ onClick = () => { }, title='Pesquisar' }) => {
    return (
        <>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<Search />}
                onClick={() => onClick()}>
                {title}
            </Button>
        </>
    )
}

export default PesquisarButton;