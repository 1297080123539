import { Box, CircularProgress } from '@material-ui/core'
import { withTheme } from '@material-ui/styles'
import React from 'react'

const Loading = ({ size, my, color, theme }) => {
    return (
        <Box display="flex" my={my}>
            <CircularProgress size={size} style={{ color: color ? color : theme.palette.primary.main }} />
        </Box>
    )
}

Loading.defaultProps = {
    my: 6,
    size: 25,
    color: undefined
}

export default withTheme(Loading)
