
import AsyncStorage from '@callstack/async-storage';

async function removeToken() {
    await AsyncStorage.removeItem('token');
}

async function setToken(token) {
    await AsyncStorage.setItem('token', token);
}

async function getToken() {

    await AsyncStorage.getItem('token');
}

export default {
    getToken,
    setToken,
    removeToken
};
