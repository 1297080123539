import axios from 'axios';

const buscarCep = (cep) => {
  const instance = axios.create({
    timeout: 10000
  });

  return instance.get(`https://viacep.com.br/ws/${cep}/json/`);
}

const UtilAPI = {
  buscarCep
}

export default UtilAPI;
