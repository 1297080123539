import {
  AppBar as MuiAppBar,
  darken,
  Grid,
  Hidden,
  IconButton as MuiIconButton,
  Toolbar,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { withTheme } from "styled-components/macro";
import UsuarioService from "../services/UsuarioService";
import MessagesDropdown from "./MessagesDropdown";
import UserDropdown from "./UserDropdown";
import * as types from "../constants";
import Alert from "./alert/Alert";
import { useHistory } from "react-router-dom";
import EmpresaComplete from "./icarus/EmpresaComplete";
import NotificationsDropdown from "./NotificationsDropdown";
const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;
  &:hover {
    background-color: ${(props) => props.theme.header.background};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const InputEmpresa = styled(EmpresaComplete)`
  color: inherit;
  padding: 8px;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

const AppBarComponent = ({ onDrawerToggle }) => {
  const { user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const changeEmpresa = (value) => {
    UsuarioService.changeCompany(value.idEmpresa).then((response) => {
      dispatch({ type: types.CHANGE_EMPRESA, company: response.company });
      Alert.success("Sucesso", "Empresa alterada com sucesso");
      history.go(0);
    });
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>

            <Grid item xl={9} md={9}>
              <Grid container>
                {user.profile.papel === "SUPORTE" && (
                  <Grid item md={4}>
                    <Search>
                      <InputEmpresa
                        value={user.company}
                        onChange={(value) => {
                          changeEmpresa(value);
                        }}
                      />
                    </Search>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs />
            <Grid item>
              <MessagesDropdown />
              <NotificationsDropdown />
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(AppBarComponent);
