// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

export const CHANGE_SELFIE_USER = "CHANGE_SELFIE_USER";
export const CHANGE_EMPRESA = "CHANGE_EMPRESA";
export const CHANGE_LAST_ACCESS = "CHANGE_LAST_ACCESS";
export const CHANGE_NOTIFICACOES = "CHANGE_NOTIFICACOES";
export const TOKEN_MAPBOX = "pk.eyJ1IjoicHNhaW5mbyIsImEiOiJja2xoejdnOWswNHdtMndxcjZzZDBjNmwyIn0.Ilns-aRd6SG7J1wtnwbKjQ";
export const LATITUDE_DEFAULT = -15.894055850789314;
export const LONGITUDE_DEFAULT = -52.257905584611386;

export const langHighcharts = {
                decimalPoint: ',',
                thousandsSep: '.',
                contextButtonTitle: 'Menu do Gráfico',
                downloadCSV: 'Download CSV',
                downloadJPEG: 'Download JPEG',
                downloadPDF: 'Download PDF',
                downloadPNG: 'Download PNG',
                downloadSVG: 'Download SVG',
                downloadXLS: 'Download XLS',
                drillUpText: 'Voltar Para {series.name}',
                loading: 'Carregando...',
                months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
                    "Julho", "Agosto", "Setembro", "Outubro", "Novembro",
                    "Dezembro"],
                navigation: {
                    popup: {
                        addButton: 'Adicionar',
                        background: 'Fundo'
                    }
                },
                tooltip: {
                    yDecimals: 2
                },
                noData: 'Nenhum dado para exibir',
                openInCloud: 'Abrir no Highcharts Cloud',
                printChart: 'Imprimir Gráfico',
                viewFullscreen: 'Visualizar em tela cheia',
                resetZoom: 'Resetar zoom',
                resetZoomTitle: 'Resetar zoom nível 1:1',
                shortMonths: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago",
                    "Set", "Out", "Nov", "Dez"],
                viewData: 'Visualizar dados',
                weekdays: ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira",
                    "Quinta-Feira", "Sexta-Feira", "Sábado"]
            }


export const SEXO = [
  {
    cod: 'MASCULINO',
    name: 'Masculino'
  },
  {
    cod: 'FEMININO',
    name: 'Feminino'
  }
]


export const DOMINIO_STATUS_EMPRESA = Object.freeze({
  ATIVA: { cod: 'ATIVA', name: 'Ativa' },
  SUSPENSA: { cod: 'SUSPENSA', name: 'Suspensa' },
  INATIVA: { cod: 'INATIVA', name: 'Inativa' },
});


export const MODALIDADE = [
  {
    cod: 'CONSIGNADO',
    name: 'Empréstimo Consignado'
  },
  {
    cod: 'PESSOAL',
    name: 'Empréstimo Pessoal'
  },
  {
    cod: 'FGTS',
    name: 'Antecipação Saque Aniversário FGTS'
  }
]

export const VINCULO = [
  {
    cod: 'EFETIVO',
    name: 'Efetivo'
  },
  {
    cod: 'TEMPORARIO',
    name: 'Temporário'
  }
]

export const BANCO_SIMULACAO = [
  {
    cod: 'PAN',
    name: 'Banco PAN'
  },
  {
    cod: 'SAFRA',
    name: 'Banco Safra'
  },
  {
    cod: 'C6',
    name: 'Banco C6'
  }
]


export const STEP_CONTRATO = [  
  {
    cod: 'SIMULACAO',
    name: 'Simulação'
  },
  {
    cod: 'VISUALIZADO_VALORES',
    name: 'Visualizado Valores Enviados Por SMS'
  },
  
  {
    cod: 'CONTRATACAO',
    name: 'Contratação'
  },
  {
    cod: 'DIGITACAO_DADOS_PESSOAIS',
    name: 'Digitação Dos Dados Pessoais'
  },
  {
    cod: 'DIGITACAO_ENDERECO',
    name: 'Digitação do Endereço'
  },
  {
    cod: 'DIGITACAO_DADOS_BANCARIOS',
    name: 'Digitação dos Dados Bancários'
  },
  {
    cod: 'CONTRATACAO_REALIZADA',
    name: 'Contratação Realizada'
  }
]

export const SITUACAO_EMPREGADO = [
  {
    cod: 'ATIVO',
    name: 'Ativo'
  },
  {
    cod: 'INATIVO_APOSENTADO',
    name: 'Inativo/Aposentado'
  },
  {
    cod: 'PENSIONISTA',
    name: 'Pensionista'
  }
]


export const REGIME_JURIDICO = [
  {
    cod: 'CLT',
    name: 'CLT'
  },
  {
    cod: 'ESTATUTARIO',
    name: 'Estatutário'
  }
]

export const TIPO_CONTA = [
  {
    cod: 'CORRENTE',
    name: 'Conta Corrente'
  },
  {
    cod: 'POUPANCA',
    name: 'Conta Poupança'
  },
]

export const ESTADO_CIVIL = [
  {
    cod: 'CASADO',
    name: 'Casado'
  },
  {
    cod: 'SOLTEIRO',
    name: 'Solteiro'
  },
  {
    cod: 'SEPARADO',
    name: 'Separado'
  },
  {
    cod: 'VIUVO',
    name: 'Viúvo'
  },
  {
    cod: 'DIVORCIADO',
    name: 'Divorciado'
  }
]

export const ESTADOS_JSON = [  
  {
    nome: 'Acre',
    sigla: 'AC'
  },
  {
    nome: 'Alagoas',
    sigla: 'AL'
  },
  {
    nome: 'Amapá',
    sigla: 'AP'
  }, {
    nome: 'Amazonas',
    sigla: 'AM'
  }, {
    nome: 'Bahia',
    sigla: 'BA'
  },
  {
    nome: 'Ceará',
    sigla: 'CE'
  },
  {
    nome: 'Distrito Federal',
    sigla: 'DF'
  }, {
    nome: 'Espírito Santo',
    sigla: 'ES'
  }, {
    nome: 'Goiás',
    sigla: 'GO'
  }, {
    nome: 'Maranhão',
    sigla: 'MA'
  }, {
    nome: 'Mato Grosso',
    sigla: 'MT'
  }, {
    nome: 'Mato Grosso do Sul',
    sigla: 'MS'
  }, {
    nome: 'Minas Gerais',
    sigla: 'MG'
  }, {
    nome: 'Pará',
    sigla: 'PA'
  }, {
    nome: 'Paraíba',
    sigla: 'PB'
  }, {
    nome: 'Paraná',
    sigla: 'PR'
  }, {
    nome: 'Pernambuco',
    sigla: 'PE'
  }, {
    nome: 'Piauí',
    sigla: 'PI'
  }, {
    nome: 'Rio de Janeiro',
    sigla: 'RJ'
  }, {
    nome: 'Rio Grande do Norte',
    sigla: 'RN'
  }, {
    nome: 'Rio Grande do Sul',
    sigla: 'RS'
  }, {
    nome: 'Rondônia',
    sigla: 'RO'
  }, {
    nome: 'Roraima',
    sigla: 'RR'
  }, {
    nome: 'Santa Catarina',
    sigla: 'SC'
  }, {
    nome: 'São Paulo',
    sigla: 'SP'
  }, {
    nome: 'Sergipe',
    sigla: 'SE'
  }, {
    nome: 'Tocantins',
    sigla: 'TO'
  }
];

export const MESSAGE_VALIDATION_INPUT = {
  REQUERID_FIELD: "Campo Obrigatório!",
  INVALID_EMAIL_FIELD: "Email Inválido!",
  INVALID_DATE: "Formato de data inválida!"
}

export const DOMINIO_ESTADO_CIVIL = Object.freeze({
  SOLTEIRO: { cod: 1, desc: "Solteiro", name: 'SOLTEIRO' },
  CASADO: { cod: 2, desc: "Casado", name: 'CASADO' },
  SEPARADO: { cod: 3, desc: "Separado", name: 'SEPARADO' },
  DIVORCIADO: { cod: 4, desc: "Divorciado", name: 'DIVORCIADO' },
  VIUVO: { cod: 5, desc: "Viúvo", name: 'VIUVO' }
});

export const DOMINIO_SIM_NAO = Object.freeze({
  SIM: { cod: 1, desc: "Sim", name: 'SIM' },
  NAO: { cod: 0, desc: "Não", name: 'NAO' }
});

export const DOMINIO_SEVERITY_MENSAGEM = Object.freeze({
  ERROR: { cod: 'error', desc: "Erro", name: 'error' },
  WARNING: { cod: 'warning', desc: "Atenção", name: 'warning' },
  INFO: { cod: 'info', desc: "Informativa", name: 'info' },
  SUCCESS: { cod: 'success', desc: "Sucesso", name: 'success' }
});

export const DOMINIO_TIPO_PERFIL = Object.freeze({
  ADMINISTRADORA: { cod: 1, desc: "Administrador", name: 'ADMINISTRADORA' },
  GESTOR: { cod: 2, desc: "Parceiro", name: 'PARCEIRO' }
});

export const DOMINIO_TIPO_NOTIFICACAO = Object.freeze({
  EMAIL: { cod: 1, desc: "E-mail", name: 'EMAIL' },
  SISTEMA: { cod: 2, desc: "Sistema", name: 'SISTEMA' },
  PUSH: { cod: 4, desc: "PUSH (Via Aplicativo)", name: 'PUSH' }
});

export const DOMINIO_ATIVO_INATIVO = Object.freeze({
  ATIVO: { cod: 1, desc: "Ativo", name: 'ATIVO' },
  INATIVO: { cod: 0, desc: "Inativo", name: 'INATIVO' }
});

export const DOMINIO_PAPEL_PERFIL = Object.freeze({
  ADMINISTRADORA: { cod: 1, desc: "Administrador", name: 'ADMINISTRADORA' },
  GESTOR: { cod: 2, desc: "Gestor", name: 'GESTOR' },
  COLABORADOR: { cod: 3, desc: "Colaborador", name: 'COLABORADOR' },
});

export const DOMINIO_PUBLICO_ALVO_MENSAGEM = Object.freeze({
  INDIVIDUAL: { cod: 1, desc: "Individual", name: 'INDIVIDUAL' },
  TODOS: { cod: 2, desc: "Todos", name: 'TODOS' },
  COLABORADORES: { cod: 5, desc: "Colaboradores", name: 'COLABORADORES' },
  ADMINISTRADORES: { cod: 3, desc: "Administradores", name: 'ADMINISTRADORES' },
  GESTORES: { cod: 4, desc: "Gestores", name: 'GESTORES' }
});

export const BANCO = [
  {
    "cod": "BANCO_GERADOR",
    "name": "BANCO GERADOR S.A."
  },
  {
    "cod": "BANCO_RODOBENS",
    "name": "BANCO RODOBENS S.A"
  },
  {
    "cod": "CC_UNICRED_NORTE_PARANA",
    "name": "CC UNICRED NORTE DO PARANA"
  },
  {
    "cod": "BANCO_RANDON",
    "name": "BANCO RANDON S.A."
  },
  {
    "cod": "UNICRED_CENTRAL_SANTA_CATARINA",
    "name": "UNICRED CENTRAL SANTA CATARINA"
  },
  {
    "cod": "BRICKELL",
    "name": "BRICKELL S A CREDITO, FINANCIA"
  },
  {
    "cod": "COOPERATIVA_DE_CREDITO_RURAL",
    "name": "COOPERATIVA DE CREDITO RURAL D"
  },
  {
    "cod": "BANCO_DE_LA_PROVINCIA_DE_BUENO",
    "name": "BANCO DE LA PROVINCIA DE BUENO"
  },
  {
    "cod": "BANCO_DO_BRASIL",
    "name": "BANCO DO BRASIL S.A."
  },
  {
    "cod": "BANCO_CENTRAL_DO_BRASIL",
    "name": "BANCO CENTRAL DO BRASIL"
  },
  {
    "cod": "BANCO_DA_AMAZONIA",
    "name": "BANCO DA AMAZONIA S.A."
  },
  {
    "cod": "BANCO_NORDESTE_DO_BRASIL",
    "name": "BANCO DO NORDESTE DO BRASIL S."
  },
  {
    "cod": "BANCO_SANTANDER_MARIDIONAL",
    "name": "BANCO SANTANDER MERIDIONAL S.A"
  },
  {
    "cod": "BANESTES",
    "name": "BANESTES S.A BANCO DO ESTADO D"
  },
  {
    "cod": "BANCO_DE_PERNANBUCO",
    "name": "BANCO DE PERNAMBUCO S.A.-BANDE"
  },
  {
    "cod": "BANCO_ALFA",
    "name": "BANCO ALFA S/A"
  },
  {
    "cod": "BANDO_DO_ESTADO_DE_SANTA_CATARINA",
    "name": "BANCO DO ESTADO DE SANTA CATAR"
  },
  {
    "cod": "BANCO_BANERJ",
    "name": "BANCO BANERJ S.A."
  },
  {
    "cod": "BANCO_SANTANDER_BRASIL",
    "name": "BANCO SANTANDER BRASIL S.A."
  },
  {
    "cod": "BANCO_BEC",
    "name": "BANCO BEC S.A."
  },
  {
    "cod": "BANCO_BEM",
    "name": "BANCO BEM S.A."
  },
  {
    "cod": "BANCO_DO_ESTADO_DO_PARA",
    "name": "BANCO DO ESTADO DO PARA S.A."
  },
  {
    "cod": "BANCO_DO_ESTADO_DO_PIAUI",
    "name": "BANCO DO ESTADO DO PIAUI S.A.-"
  },
  {
    "cod": "BANCO_CARGILL",
    "name": "BANCO CARGILL S.A"
  },
  {
    "cod": "BANCO_DO_ESTADO_DO_RIO_GRANDE",
    "name": "BANCO DO ESTADO DO RIO GRANDE"
  },
  {
    "cod": "BANCO_BVA_SA",
    "name": "BANCO BVA SA"
  },
  {
    "cod": "BANCO_OPPORTUNITY",
    "name": "BANCO OPPORTUNITY S.A."
  },
  {
    "cod": "BANCO_DO_ESTADO_DE_SERGIPE",
    "name": "BANCO DO ESTADO DE SERGIPE S.A"
  },
  {
    "cod": "HIPERCARD_BANCO_MULTIPLO",
    "name": "HIPERCARD BANCO MULTIPLO S.A"
  },
  {
    "cod": "BANCO_IBI_S_A",
    "name": "BANCO IBI S.A - BANCO MULTIPLO"
  },
  {
    "cod": "LEMON_BANK_BANCO_MULTIPLO",
    "name": "LEMON BANK BANCO MULTIPLO S.A"
  },
  {
    "cod": "BANCO_MORGAN_STANLEY_DEAN_WITT",
    "name": "BANCO MORGAN STANLEY DEAN WITT"
  },
  {
    "cod": "BPN_BRASIL_BANCO_MULTIPLO",
    "name": "BPN BRASIL BANCO MULTIPLO S.A"
  },
  {
    "cod": "BRB_CREDITO_FINANC_E_INVEST",
    "name": "BRB CREDITO FINANC E INVEST SA"
  },
  {
    "cod": "BANCO_RURAL_MAIS",
    "name": "BANCO RURAL MAIS S.A"
  },
  {
    "cod": "BB_BANCO_POPULAR_DO_BRASIL",
    "name": "BB BANCO POPULAR DO BRASIL"
  },
  {
    "cod": "BANCO_CR2",
    "name": "BANCO CR2 S.A"
  },
  {
    "cod": "BANCO_KDB_DO_BRASIL",
    "name": "BANCO KDB DO BRASIL S.A"
  },
  {
    "cod": "BANCO_BM_F_DE_SERV_DE_LIQUIDA",
    "name": "BANCO BM&F DE SERV. DE LIQUIDA"
  },
  {
    "cod": "CAIXA_ECONOMICA_FEDERAL",
    "name": "CAIXA ECONOMICA FEDERAL"
  },
  {
    "cod": "BANCO_BBM",
    "name": "BANCO BBM S.A"
  },
  {
    "cod": "BANCO_UNICO",
    "name": "BANCO UNICO S.A."
  },
  {
    "cod": "BANCO_NOSSA_CAIXA",
    "name": "BANCO NOSSA CAIXA S.A"
  },
  {
    "cod": "BANCO_FINASA",
    "name": "BANCO FINASA S.A."
  },
  {
    "cod": "BANCO_ITAU_BBA",
    "name": "BANCO ITAU BBA S.A"
  },
  {
    "cod": "AMERICAN_EXPRESS_BANK",
    "name": "AMERICAN EXPRESS BANK(BRASIL)"
  },
  {
    "cod": "BANCO_PACTUAL",
    "name": "BANCO PACTUAL S.A."
  },
  {
    "cod": "DRESDNER_BANK_LATEINAMERIKA",
    "name": "DRESDNER BANK LATEINAMERIKA AK"
  },
  {
    "cod": "BANCO_ORIGINAL",
    "name": "BANCO ORIGINAL"
  },
  {
    "cod": "BANCO_ARBI",
    "name": "BANCO ARBI S.A."
  },
  {
    "cod": "BANCO_DIBENS",
    "name": "BANCO DIBENS S.A."
  },
  {
    "cod": "BANCO_COMERCIAL_E_DE_INVESTIME",
    "name": "BANCO COMERCIAL E DE INVESTIME"
  },
  {
    "cod": "BANCO_JOHN_DEERE",
    "name": "BANCO JOHN DEERE S.A."
  },
  {
    "cod": "BANCO_BONSUCESSO",
    "name": "BANCO BONSUCESSO S.A."
  },
  {
    "cod": "BANCO_CALYON_BRASIL",
    "name": "BANCO CALYON BRASIL S/A"
  },
  {
    "cod": "BANCO_FIBRA",
    "name": "BANCO FIBRA S.A."
  },
  {
    "cod": "BANCO_BRASCAN",
    "name": "BANCO BRASCAN S.A."
  },
  {
    "cod": "BCSUL",
    "name": "BCSUL"
  },
  {
    "cod": "UNICARD_BANCO_MULTIPLO",
    "name": "UNICARD BANCO MULTIPLO S.A"
  },
  {
    "cod": "BANCO_GE_CAPITAL",
    "name": "BANCO GE CAPITAL S.A"
  },
  {
    "cod": "BANCO_CLASSICO",
    "name": "BANCO CLASSICO S.A."
  },
  {
    "cod": "BANCO_MAXIMA",
    "name": "BANCO MAXIMA S.A."
  },
  {
    "cod": "BANCO_ABC_BRASIL",
    "name": "BANCO ABC BRASIL S.A."
  },
  {
    "cod": "BANCO_UBS",
    "name": "BANCO UBS S.A"
  },
  {
    "cod": "BANCO_BOA_VISTA_INTERATLANTICO",
    "name": "BANCO BOA VISTA INTERATLANTICO"
  },
  {
    "cod": "BANCO_INVESTCRED_UNIBANCO",
    "name": "BANCO INVESTCRED UNIBANCO S.A"
  },
  {
    "cod": "BANCO_SCHAHIN",
    "name": "BANCO SCHAHIN S.A"
  },
  {
    "cod": "BANCO_FININVEST",
    "name": "BANCO FININVEST S.A."
  },
  {
    "cod": "PARANA_BANCO",
    "name": "PARANA BANCO S.A."
  },
  {
    "cod": "BANCO_CACIQUE",
    "name": "BANCO CACIQUE S.A."
  },
  {
    "cod": "BANCO_FATOR",
    "name": "BANCO FATOR S.A."
  },
  {
    "cod": "BANCO_CEDULA",
    "name": "BANCO CEDULA S.A."
  },
  {
    "cod": "BANCO_DE_LA_NACION_ARGENTINA",
    "name": "BANCO DE LA NACION ARGENTINA"
  },
  {
    "cod": "BANCO_BMG",
    "name": "BANCO BMG S.A."
  },
  {
    "cod": "BANCO_INDUSTRIAL_COMERCIAL",
    "name": "BANCO INDUSTRIAL E COMERCIAL S"
  },
  {
    "cod": "BANCO_ITAU",
    "name": "BANCO ITAU S.A."
  },
  {
    "cod": "BANCO_SUDAMERIS_DO_BRASIL",
    "name": "BANCO SUDAMERIS DO BRASIL S.A"
  },
  {
    "cod": "BANCO_SANTANDER",
    "name": "BANCO SANTANDER S.A."
  },
  {
    "cod": "BANCO_SANTANDER_BRASIL_S_A",
    "name": "BANCO SANTANDER BRASIL S.A."
  },
  {
    "cod": "BANCO_ABN_AMRO_REAL",
    "name": "BANCO ABN AMRO REAL S.A."
  },
  {
    "cod": "BANCO_SOCIETE_GENERALE_BRASIL",
    "name": "BANCO SOCIETE GENERALE BRASIL"
  },
  {
    "cod": "BANCO_WESTLB_DO_BRASIL",
    "name": "BANCO WESTLB DO BRASIL S.A."
  },
  {
    "cod": "BANCO_J_P_MORGAN",
    "name": "BANCO J.P. MORGAN S.A."
  },
  {
    "cod": "BANCO_MERCANTIL_DO_BRASIL",
    "name": "BANCO MERCANTIL DO BRASIL S.A."
  },
  {
    "cod": "BANCO_BMC",
    "name": "BANCO BMC S.A."
  },
  {
    "cod": "HSBC_BANK_BRASIL",
    "name": "HSBC BANK BRASIL S.A.-BANCO MU"
  },
  {
    "cod": "UNIBANCO",
    "name": "UNIBANCO - UNIAO DE BANCOS BRA"
  },
  {
    "cod": "BANCO_CAPITAL",
    "name": "BANCO CAPITAL S.A."
  },
  {
    "cod": "BANCO_SAFRA",
    "name": "BANCO SAFRA S.A."
  },
  {
    "cod": "BANCO_RURAL",
    "name": "BANCO RURAL S.A."
  },
  {
    "cod": "BANCO_DE_TOKYO_MITSUBISHI",
    "name": "BANCO DE TOKYO MITSUBISHI UFJ"
  },
  {
    "cod": "BANCO_SUMITOMO_MITSUI_BRASILEI",
    "name": "BANCO SUMITOMO MITSUI BRASILEI"
  },
  {
    "cod": "CITIBANK",
    "name": "CITIBANK N.A."
  },
  {
    "cod": "BANKBOSTON_BANCO_MULTIPLO",
    "name": "BANKBOSTON BANCO MULTIPLO S.A."
  },
  {
    "cod": "JPMORGAN_CHASE_BANK",
    "name": "JPMORGAN CHASE BANK"
  },
  {
    "cod": "ING_BANK",
    "name": "ING BANK N.V."
  },
  {
    "cod": "BANCO_CREDIT_SUISSE",
    "name": "BANCO CREDIT SUISSE (BRASIL) S"
  },
  {
    "cod": "BANCO_INDUSTRIAL_DO_BRASIL",
    "name": "BANCO INDUSTRIAL DO BRASIL S."
  },
  {
    "cod": "BANCO_VR",
    "name": "BANCO VR S.A."
  },
  {
    "cod": "BANCO_GUANABARA",
    "name": "BANCO GUANABARA S.A."
  },
  {
    "cod": "BANCO_PANAMERICANO",
    "name": "BANCO PANAMERICANO S.A."
  },
  {
    "cod": "BANCO_RENDIMENTO",
    "name": "BANCO RENDIMENTO S.A."
  },
  {
    "cod": "BANCO_TRIANGULO",
    "name": "BANCO TRIANGULO S.A."
  },
  {
    "cod": "BANCO_PROSPER",
    "name": "BANCO PROSPER S.A."
  },
  {
    "cod": "BANCO_ITAU_HOLDING_FINANCEIRA",
    "name": "BANCO ITAU HOLDING FINANCEIRA"
  },
  {
    "cod": "BANCO_A_J_RENNER",
    "name": "BANCO A.J. RENNER S.A."
  },
  {
    "cod": "BANCO_VOTORANTIM",
    "name": "BANCO VOTORANTIM S.A."
  },
  {
    "cod": "BANIF_BANCO_INTERNACIONAL",
    "name": "BANIF-BANCO INTERNACIONAL DO F"
  },
  {
    "cod": "BANCO_GERDAU",
    "name": "BANCO GERDAU S.A."
  },
  {
    "cod": "BANCO_MORADA",
    "name": "BANCO MORADA S.A."
  },
  {
    "cod": "BANCO_BGN",
    "name": "BANCO BGN S.A."
  },
  {
    "cod": "BANCO_RIBEIRAO_PRETO",
    "name": "BANCO RIBEIRAO PRETO S.A."
  },
  {
    "cod": "BANKBOSTON",
    "name": "BANKBOSTON N.A."
  },
  {
    "cod": "BANCO_CITIBANK",
    "name": "BANCO CITIBANK S.A."
  },
  {
    "cod": "BANCO_RABOBANK_INTERNATIONAL",
    "name": "BANCO RABOBANK INTERNATIONAL B"
  },
  {
    "cod": "BANCO_SIMPLES",
    "name": "BANCO SIMPLES S.A."
  },
  {
    "cod": "BANCO_BNP_PARIBAS_BRASIL",
    "name": "BANCO BNP PARIBAS BRASIL S.A"
  },
  {
    "cod": "BANCO_COMERCIAL_URUGUAI",
    "name": "BANCO COMERCIAL URUGUAI S.A."
  },
  {
    "cod": "BANCO_KEB_DO_BRASIL",
    "name": "BANCO KEB DO BRASIL S.A."
  },
  {
    "cod": "BANCO_BRADESCO",
    "name": "BANCO BRADESCO S.A."
  },
  {
    "cod": "BANCO_BEG",
    "name": "BANCO BEG S.A"
  },
  {
    "cod": "GOLDMAN_SACHS_DO_BRASIL_BANCO",
    "name": "GOLDMAN SACHS DO BRASIL BANCO "
  },
  {
    "cod": "BANCO_MAXINVEST",
    "name": "BANCO MAXINVEST S.A"
  },
  {
    "cod": "BANCO_PORTO_SEGURO",
    "name": "BANCO PORTO SEGURO S.A"
  },
  {
    "cod": "BANCO_MERRILL_DE_INVESTIMENTOS",
    "name": "BANCO MERRILL DE INVESTIMENTOS"
  },
  {
    "cod": "BANCO_BRJ",
    "name": "BANCO BRJ S.A"
  },
  {
    "cod": "BANCO_FIAT",
    "name": "BANCO FIAT S.A"
  },
  {
    "cod": "BANCO_OURINVEST",
    "name": "BANCO OURINVEST S.A"
  },
  {
    "cod": "TRICURY",
    "name": "TRICURY S.A"
  },
  {
    "cod": "COOPERATIVA_CENTRAL_ECONOMIA",
    "name": "COOPERATIVA CENTRAL ECONOMIA E"
  },
  {
    "cod": "CREDIALINCA_COOP_DE_CREDITO_RURAL",
    "name": "CREDIALINÇA COOP DE CREDITO RURAL"
  },
  {
    "cod": "BANCO_NACIONAL_DO_DESENVOLVIME",
    "name": "BANCO NACIONAL DO DESENVOLVIME"
  },
  {
    "cod": "BANCO_STANDARD_DE_INVESTIMENTO",
    "name": "BANCO STANDARD DE INVESTIMENTO"
  },
  {
    "cod": "BANCO_AZTECA_DO_BRASIL",
    "name": "BANCO AZTECA DO BRASIL S.A."
  },
  {
    "cod": "BANCO_DO_ESTADO_DE_ALAGOAS",
    "name": "BANCO DO ESTADO DE ALAGOAS S.A"
  },
  {
    "cod": "CREDIREAL_EM_ABSORCAO",
    "name": "CREDIREAL -EM ABSORCAO"
  },
  {
    "cod": "BANCO_DO_ESTADO_DO_ACRE",
    "name": "BANCO DO ESTADO DO ACRE S.A."
  },
  {
    "cod": "PARAIBAN_BANCO_DA_PARAIBA",
    "name": "PARAIBAN-BANCO DA PARAIBA S.A."
  },
  {
    "cod": "BANCO_DO_ESTADO_DO_MATO_GROSSO",
    "name": "BANCO DO ESTADO DO MATO GROSSO"
  },
  {
    "cod": "BANCO_BEA",
    "name": "BANCO BEA S.A."
  },
  {
    "cod": "BANCO_J_SAFRA",
    "name": "BANCO J. SAFRA S.A"
  },
  {
    "cod": "BANCO_BEMGE",
    "name": "BANCO BEMGE S.A."
  },
  {
    "cod": "BANCO_DO_ESTADO_DE_RONDONIA",
    "name": "BANCO DO ESTADO DE RONDONIA S."
  },
  {
    "cod": "BANCO_ABB",
    "name": "BANCO ABB SA"
  },
  {
    "cod": "BANCO_BANEB",
    "name": "BANCO BANEB SA"
  },
  {
    "cod": "BES_INVESTIMENTO_DO_BRASIL",
    "name": "BES INVESTIMENTO DO BRASIL SA"
  },
  {
    "cod": "JBS_BANCO",
    "name": "JBS BANCO S.A."
  },
  {
    "cod": "BANCO_ITABANCO",
    "name": "BANCO ITABANCO S.A."
  },
  {
    "cod": "BANK_OF_AMERICA",
    "name": "BANK OF AMERICA - BRASIL S.A."
  },
  {
    "cod": "CAIXA_ECONOMICA_ESTADUAL_DO_RI",
    "name": "CAIXA ECONOMICA ESTADUAL DO RI"
  },
  {
    "cod": "BANCO_NORCHEM",
    "name": "BANCO NORCHEM S.A."
  },
  {
    "cod": "BANCO_INTER_ATLANTICO",
    "name": "BANCO INTER-ATLANTICO S.A."
  },
  {
    "cod": "HSBC_INVESTMENT_BANK_BRASIL",
    "name": "HSBC INVESTMENT BANK BRASIL S."
  },
  {
    "cod": "BANCO_FINANCIAL_PORTUGUES",
    "name": "BANCO FINANCIAL PORTUGUES"
  },
  {
    "cod": "BANCO_FICRISA_AXELRUD",
    "name": "BANCO FICRISA AXELRUD S.A."
  },
  {
    "cod": "BANCO_AXIAL",
    "name": "BANCO AXIAL S.A."
  },
  {
    "cod": "BANCO_SUL_AMERICA",
    "name": "BANCO SUL AMERICA S.A."
  },
  {
    "cod": "BANCO_MARTINELLI",
    "name": "BANCO MARTINELLI S.A."
  },
  {
    "cod": "BANCO_SISTEMA",
    "name": "BANCO SISTEMA S.A."
  },
  {
    "cod": "BANCO_REGIONAL_MALCON",
    "name": "BANCO REGIONAL MALCON S.A."
  },
  {
    "cod": "BANCO_ZOGBI",
    "name": "BANCO ZOGBI S.A"
  },
  {
    "cod": "BANCO_CREFISUL",
    "name": "BANCO CREFISUL S.A."
  },
  {
    "cod": "BANCO_ICATU",
    "name": "BANCO ICATU S.A."
  },
  {
    "cod": "BANCO_BOAVISTA_INTERATLANTICO",
    "name": "BANCO BOAVISTA INTERATLANTICO"
  },
  {
    "cod": "BANCO_INTERPART",
    "name": "BANCO INTERPART S.A."
  },
  {
    "cod": "BANCO_CAMBIAL",
    "name": "BANCO CAMBIAL SA"
  },
  {
    "cod": "BANCO_BANCRED",
    "name": "BANCO BANCRED S.A."
  },
  {
    "cod": "CC_UNICRED_BRASIL_CENTRAL",
    "name": "CC UNICRED BRASIL CENTRAL"
  },
  {
    "cod": "BANCO_WOORI_BANK_DO_BRASIL",
    "name": "BANCO WOORI BANK DO BRASIL S.A"
  },
  {
    "cod": "BANCO_DE_CREDITO_REAL_DE_MINAS",
    "name": "BANCO DE CREDITO REAL DE MINAS"
  },
  {
    "cod": "BANCO_EUROINVEST_S_A_EUROBANC",
    "name": "BANCO EUROINVEST S.A. EUROBANC"
  },
  {
    "cod": "BANCO_CIDADE",
    "name": "BANCO CIDADE S.A."
  },
  {
    "cod": "BANCO_EMPRESARIAL",
    "name": "BANCO EMPRESARIAL S.A."
  },
  {
    "cod": "MILBANCO",
    "name": "MILBANCO S.A."
  },
  {
    "cod": "BANCO_GULFINVEST",
    "name": "BANCO GULFINVEST S.A."
  },
  {
    "cod": "BANCO_INDUSCRED",
    "name": "BANCO INDUSCRED S.A."
  },
  {
    "cod": "BANCO_BOREAL",
    "name": "BANCO BOREAL S.A."
  },
  {
    "cod": "BANCO_BBM_COM_C_IMOB_CFI",
    "name": "BANCO BBM-COM.C.IMOB.CFI S.A."
  },
  {
    "cod": "BANCO_PLANIBANC",
    "name": "BANCO PLANIBANC S.A."
  },
  {
    "cod": "BANCO_BRASILEIRO_COMERCIAL",
    "name": "BANCO BRASILEIRO COMERCIAL S.A"
  },
  {
    "cod": "BCR_EM_ABSORCAO",
    "name": "BCR/EM ABSORCAO"
  },
  {
    "cod": "BANCO_DO_PROGRESSO",
    "name": "BANCO DO PROGRESSO S.A."
  },
  {
    "cod": "BANCO_HNF",
    "name": "BANCO HNF S.A."
  },
  {
    "cod": "BANCO_PONTUAL",
    "name": "BANCO PONTUAL S.A."
  },
  {
    "cod": "BANCO_BFB",
    "name": "BANCO BFB _ EM ABSORCAO"
  },
  {
    "cod": "BANCO_DIGIBANCO",
    "name": "BANCO DIGIBANCO S.A."
  },
  {
    "cod": "BANCO_ITAMARATI",
    "name": "BANCO ITAMARATI S/A"
  },
  {
    "cod": "BANCO_FENICIA",
    "name": "BANCO FENICIA S.A."
  },
  {
    "cod": "BANCO_BMD",
    "name": "BANCO BMD S.A."
  },
  {
    "cod": "BANCO_MERCANTIL_DE_SAO_PAULO",
    "name": "BANCO MERCANTIL DE SAO PAULO S"
  },
  {
    "cod": "BANCO_NACIONAL",
    "name": "BANCO NACIONAL S.A."
  },
  {
    "cod": "BANORTE_BANCO_NACIONAL_DO_NO",
    "name": "BANORTE - BANCO NACIONAL DO NO"
  },
  {
    "cod": "LECCA_CRED_FINANC_E_INVEST",
    "name": "LECCA CRÉD FINANC E INVEST S.A"
  },
  {
    "cod": "BANFORT_BANCO_FORTALEZ",
    "name": "BANFORT - BANCO FORTALEZA S.A."
  },
  {
    "cod": "LLOYDS_TSB_BANK",
    "name": "LLOYDS TSB BANK PLC"
  },
  {
    "cod": "BANCO_FINANCIAL_PORTUGUES_EM",
    "name": "BANCO FINANCIAL PORTUGUES-EM A"
  },
  {
    "cod": "BANCO_WACHOVIA",
    "name": "BANCO WACHOVIA S.A."
  },
  {
    "cod": "BANCO_FRANCES",
    "name": "BANCO FRANCES INTERNACIONAL-BR"
  },
  {
    "cod": "BANCO_UNION",
    "name": "BANCO UNION - BRASIL S.A"
  },
  {
    "cod": "BANCO_UNO_E_BRASIL",
    "name": "BANCO UNO-E BRASIL S.A"
  },
  {
    "cod": "CENTRO_HISPANO_BANCO",
    "name": "CENTRO HISPANO BANCO"
  },
  {
    "cod": "BANCO_IOCHPE",
    "name": "BANCO IOCHPE S.A."
  },
  {
    "cod": "BANCO_BRASILEIRO_IRAQUIANO",
    "name": "BANCO BRASILEIRO IRAQUIANO S.A"
  },
  {
    "cod": "BANCO_SANTANDER_DE_NEGOCIOS",
    "name": "BANCO SANTANDER DE NEGOCIOS S."
  },
  {
    "cod": "BANCO_MULTIPLIC",
    "name": "BANCO MULTIPLIC S.A."
  },
  {
    "cod": "BANCO_PATENTE",
    "name": "BANCO PATENTE S.A."
  },
  {
    "cod": "BANCO_SANTOS_NEVES",
    "name": "BANCO SANTOS NEVES S.A."
  },
  {
    "cod": "BANCO_TENDENCIA",
    "name": "BANCO TENDENCIA S.A."
  },
  {
    "cod": "BANCO_APLICAP",
    "name": "BANCO APLICAP S.A."
  },
  {
    "cod": "BANCO_GENERAL_MOTORS",
    "name": "BANCO GENERAL MOTORS S.A"
  },
  {
    "cod": "BANCO_ARAUCARIA",
    "name": "BANCO ARAUCARIA S.A."
  },
  {
    "cod": "BANCO_DESTAK",
    "name": "BANCO DESTAK S.A."
  },
  {
    "cod": "BANCO_CRITERIUM",
    "name": "BANCO CRITERIUM S. A."
  },
  {
    "cod": "BANCO_DO_ESTADO_AMAPA",
    "name": "BANCO DO ESTADO AMAPA S.A."
  },
  {
    "cod": "BANCO_CREDITO_METROPOLITANO",
    "name": "BANCO CREDITO METROPOLITANO S/"
  },
  {
    "cod": "BANCO_DO_ESTADO_DE_RORAIMA",
    "name": "BANCO DO ESTADO DE RORAIMA S.A"
  },
  {
    "cod": "BANCO_MARKA",
    "name": "BANCO MARKA S.A."
  },
  {
    "cod": "BANCO_DIMENSAO",
    "name": "BANCO DIMENSAO S.A."
  },
  {
    "cod": "BANCO_PEBB",
    "name": "BANCO PEBB S.A."
  },
  {
    "cod": "BANCO_MATRIX",
    "name": "BANCO MATRIX S.A."
  },
  {
    "cod": "BANCO_TECNICORP",
    "name": "BANCO TECNICORP S.A."
  },
  {
    "cod": "BANCO_PORTO_REAL",
    "name": "BANCO PORTO REAL S.A."
  },
  {
    "cod": "BANCO_SANTOS",
    "name": "BANCO SANTOS S. A."
  },
  {
    "cod": "BANCO_VETOR",
    "name": "BANCO VETOR S.A."
  },
  {
    "cod": "BANCO_VEGA",
    "name": "BANCO VEGA S.A."
  },
  {
    "cod": "BANCO_OPERADOR",
    "name": "BANCO OPERADOR S.A."
  },
  {
    "cod": "BANCO_INTERIOR_DE_SAO_PAULO",
    "name": "BANCO INTERIOR DE SAO PAULO S."
  },
  {
    "cod": "BANCO_FINANSINOS",
    "name": "BANCO FINANSINOS S. A."
  },
  {
    "cod": "BANCO_FITAL",
    "name": "BANCO FITAL S.A."
  },
  {
    "cod": "BANCO_FONTE_CINDAM",
    "name": "BANCO FONTE CINDAM S.A."
  },
  {
    "cod": "BANCO_MINAS",
    "name": "BANCO MINAS S.A."
  },
  {
    "cod": "BANCO_DAS_NACOES",
    "name": "BANCO DAS NACOES S.A."
  },
  {
    "cod": "BANCO_THECA",
    "name": "BANCO THECA S.A."
  },
  {
    "cod": "BANCO_EQUATORIAL",
    "name": "BANCO EQUATORIAL S.A."
  },
  {
    "cod": "HSBC_REPUBLIC_BANK_BRASIL",
    "name": "HSBC REPUBLIC BANK BRASIL S.A-"
  },
  {
    "cod": "BCR_BANCO_DE_CREDITO_REAL",
    "name": "BCR BANCO DE CREDITO REAL S.A"
  },
  {
    "cod": "BANCO_INTERMEDIUM",
    "name": "BANCO INTERMEDIUM S.A."
  },
  {
    "cod": "BANCO_DA_CHINA_BRASIL",
    "name": "BANCO DA CHINA BRASIL S.A."
  },
  {
    "cod": "NATIXIS_BRASIL",
    "name": "NATIXIS BRASIL S.A. - BANCO M"
  },
  {
    "cod": "BANCO_PETRA",
    "name": "Banco Petra S.A."
  },
  {
    "cod": "CENTRAL_DE_COOPERATIVAS_DE_ECO",
    "name": "CENTRAL DE COOPERATIVAS DE ECO"
  },
  {
    "cod": "BANCO_WESTERN_UNION_DO_BRASIL",
    "name": "BANCO WESTERN UNION DO BRASIL"
  },
  {
    "cod": "CECOOPES_CENTRAL_DAS_COOP",
    "name": "CECOOPES-CENTRAL DAS COOP DE E"
  },
  {
    "cod": "BANCO_BERJ",
    "name": "BANCO BERJ S.A"
  },
  {
    "cod": "CONCORDIA_BANCO",
    "name": "CONCORDIA BANCO S.A."
  },
  {
    "cod": "BANCO_TOPAZIO",
    "name": "BANCO TOPÁZIO"
  },
  {
    "cod": "OBOE_CREDITO_FINANCIAMENTO",
    "name": "OBOE CREDITO, FINANCIAMENTO E"
  },
  {
    "cod": "COOPERATIVA_CENTRAL_DE_CREDITO",
    "name": "COOPERATIVA CENTRAL DE CREDITO"
  },
  {
    "cod": "BANCO_BCN",
    "name": "BANCO BCN S.A."
  },
  {
    "cod": "DEUTSCHE_BANK",
    "name": "DEUTSCHE BANK S. A. - BANCO AL"
  },
  {
    "cod": "BANCO_DE_LA_REPUBLICA_ORIENTAL",
    "name": "BANCO DE LA REPUBLICA ORIENTAL"
  },
  {
    "cod": "BANCO_LUSO_BRASILEIRO",
    "name": "BANCO LUSO BRASILEIRO S.A."
  },
  {
    "cod": "BANCO_PAULISTA",
    "name": "BANCO PAULISTA S.A."
  },
  {
    "cod": "BANCO_PECUNIA",
    "name": "BANCO PECUNIA S.A."
  },
  {
    "cod": "BANCO_FICSA",
    "name": "BANCO FICSA S.A."
  },
  {
    "cod": "BANCO_INTERCAP",
    "name": "BANCO INTERCAP S.A."
  },
  {
    "cod": "BANCO_SOFISA",
    "name": "BANCO SOFISA S.A."
  },
  {
    "cod": "BANCO_PINE",
    "name": "BANCO PINE S.A."
  },
  {
    "cod": "BANCO_INDUSVAL",
    "name": "BANCO INDUSVAL S.A."
  },
  {
    "cod": "BANCO_DAYCOVAL",
    "name": "BANCO DAYCOVAL S.A."
  },
  {
    "cod": "BANCO_CREDIBEL",
    "name": "BANCO CREDIBEL S.A."
  },
  {
    "cod": "BANCO_POTTENCIAL",
    "name": "BANCO POTTENCIAL S.A."
  },
  {
    "cod": "BANCO_BARCLAYS",
    "name": "BANCO BARCLAYS S.A."
  },
  {
    "cod": "BANCO_SEMEAR",
    "name": "BANCO SEMEAR S.A."
  },
  {
    "cod": "BANCO_MODAL",
    "name": "BANCO MODAL S.A."
  },
  {
    "cod": "BANCO_COOPERATIVO_SICREDI",
    "name": "BANCO COOPERATIVO SICREDI S.A."
  },
  {
    "cod": "DRESDNER_BANK_BRASIL",
    "name": "DRESDNER BANK BRASIL S.A. BANC"
  },
  {
    "cod": "BANCO_COOPERATIVO_DO_BRASIL",
    "name": "BANCO COOPERATIVO DO BRASIL S."
  },
  {
    "cod": "SUL_FINANCEIRA",
    "name": "SUL FINANCEIRA"
  },
  {
    "cod": "UNICRED",
    "name": "UNICRED"
  },
  {
    "cod": "CRESOL",
    "name": "CRESOL"
  },
  {
    "cod": "BANCO_6",
    "name": "BANCO 6"
  },
  {
    "cod": "NU_PAGAMENTOS",
    "name": "NU PAGAMENTOS S.A."
  },
  {
    "cod": "FINANCEIRA_ALFA",
    "name": "FINANCEIRA ALFA S/A - CREDITO"
  },
  {
    "cod": "FACTA_FINANCEIRA",
    "name": "FACTA FINANCEIRA"
  },
  {
    "cod": "CREDICOAMO",
    "name": "Credicoamo"
  },
  {
    "cod": "MERCADO_PAGO",
    "name": "MERCADO PAGO"
  },
  {
    "cod": "PAG_SEGURO_INTERNET",
    "name": "PAG SEGURO INTERNET"
  },
  {
    "cod": "BANCO_523",
    "name": "BANCO 523"
  },
  {
    "cod": "C6_BANK",
    "name": "C6 BANK S.A."
  },
  {
    "cod": "BECKER_FINANCEIRA",
    "name": "BECKER FINANCEIRA"
  },
  {
    "cod": "VALOR_SOCIEDADE",
    "name": "VALOR SOCIEDADE"
  },
  {
    "cod": "BANCO_OLE_BONSUCESSO",
    "name": "BANCO OLE BONSUCESSO CONSIGNADO S/A"
  },
  {
    "cod": "BANCO_INTER",
    "name": "BANCO INTER"
  },
  {
    "cod": "BANCO_335",
    "name": "BANCO 335"
  },
  {
    "cod": "CCB_BRASIL",
    "name": "CCB BRASIL S/A"
  },
  {
    "cod": "AGIBANK",
    "name": "AGIBANK FINANCEIRA S/A"
  },
  {
    "cod": "RETORNO_DATAPREV",
    "name": "RETORNO DATAPREV"
  },
  {
    "cod": "SICOOB_CREDICENM",
    "name": "SICOOB CREDICENM"
  },
  {
    "cod": "HS_FINANCEIRA",
    "name": "HS FINANCEIRA S.A - CREDITO, FINANCIAMENTO E INVESTIMENTOS"
  },
  {
    "cod": "ZEMA_CREDITO",
    "name": "ZEMA CREDITO, FINANCIAMENTO E INVESTIMENTO S/A"
  },
  {
    "cod": "CREFISA",
    "name": "CREFISA CFI"
  },
  {
    "cod": "PARATI_CREDITO",
    "name": "PARATI CREDITO S/A"
  },
  {
    "cod": "SANTINVEST",
    "name": "SANTINVEST S/A"
  },
  {
    "cod": "BARI",
    "name": "BARI"
  },
  {
    "cod": "ASPECIR",
    "name": "ASPECIR"
  },
  {
    "cod": "CREDIARE",
    "name": "CREDIARE S/A CREDITO"
  },
  {
    "cod": "BRB_CREDITOS",
    "name": "BRB CREDITOS S/A"
  },
  {
    "cod": "MERCANTIL_DO_BRASIL_FINANCEIRA",
    "name": "MERCANTIL DO BRASIL FINANCEIRA S/A"
  },
  {
    "cod": "GAZINCRED_S_A_SOCIEDADE_DE_CREDITO",
    "name": "GAZINCRED S/A SOCIEDADE DE CREDITO"
  },
  {
    "cod": "BANCO_SICOOB_COOPERNAPI",
    "name": "BANCO SICOOB COOPERNAPI"
  },
  {
    "cod": "DIGIO",
    "name": "DIGIO"
  },
  {
    "cod": "BANCO_273",
    "name": "BANCO 273"
  },
  {
    "cod": "BANCO_301",
    "name": "BANCO 301"
  },
  {
    "cod": "BANCO_276",
    "name": "BANCO 276"
  },
  {
    "cod": "BANCO_123",
    "name": "BANCO 123"
  },
  {
    "cod": "BANCO_326",
    "name": "BANCO 326"
  },
  {
    "cod": "BANCO_93",
    "name": "BANCO 93"
  },
  {
    "cod": "BANCO_CORA",
    "name": "BANCO CORA"
  },
  {
    "cod": "BANCO_363",
    "name": "BANCO 363"
  },
  {
    "cod": "BANCO_BARI_DE_INVESTIMENTOS_E_FINANCIAMENTOS",
    "name": "BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS SA"
  },
  {
    "cod": "AVISTA_S_A_CRÉDITO_FINANCIAMENTO_E_INVESTIMENTO",
    "name": "AVISTA S.A CRÉDITO, FINANCIAMENTO E INVESTIMENTO"
  },
  {
    "cod": "STONE_PAGAMENTOS",
    "name": "STONE PAGAMENTOS S.A."
  },
  {
    "cod": "NAO_EXISTE_INFORMACAO",
    "name": "NÃO EXISTE INFORMAÇÃO"
  },
  {
    "cod": "BANCO_60",
    "name": "BANCO 60"
  }
]