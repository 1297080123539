import Alert from "../../components/alert/Alert";
import * as types from "../../constants";
import {
  resetPassword as authResetPassword, signIn as authSignIn,


  validaToken as validaTokenService
} from "../../services/authService";

export function signIn(credentials) {

  return async dispatch => {
    function onSuccess(response) {
      dispatch({ type: types.AUTH_SIGN_IN_SUCCESS, user: response.data });
      return response;
    }
    function onError(error) {
      dispatch({ type: types.AUTH_SIGN_IN_FAILURE, error });
      throw error;
    }
    try {
      const success = await authSignIn(credentials);
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  }
}

export function validaToken(token) {
  return async dispatch => {
    function onSuccess(response) {
      dispatch({ type: types.AUTH_SIGN_IN_SUCCESS, user: response.data });
      return response;
    }
    function onError(error) {
      dispatch({ type: types.AUTH_SIGN_IN_FAILURE, error });
      throw error;
    }
    try {
      const success = await validaTokenService(token);
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  }
}

export function signOut() {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_SIGN_OUT,
    });
  };
}

export function resetPassword(username) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

  return authResetPassword(username)
    .then((response) => {
        Alert.success('Sucesso','Uma mensagem foi encaminhada para o seu email contendo as instruções de recuperação de senha');
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
          msgResetPasswordSuccess: response,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}
