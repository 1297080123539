import { Button, Grid } from '@material-ui/core';
import { FaFileCsv, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import Alert from '../alert/Alert';

export const exportCSV = (refTable, objPage) => {
    if (objPage === null || objPage === undefined || objPage.length <= 0) {
        Alert.error('', 'Não há dados a serem exportados.');
        return;
    }
    refTable.exportCSV();
};

const tabelaHigienizada = (id) => {

    const tabela = document.getElementsByClassName(id)[0];

    const clona = tabela.cloneNode(true);
    const remover = clona.getElementsByClassName("p-datatable-tbody")[0].getElementsByClassName("p-column-title")

    for (let index = remover.length - 1; index >= 0; index--) {
        const element = remover[index];
        element.remove()
    }

    if (clona.getElementsByClassName('MuiButtonBase-root MuiButton-root')[0] &&
        clona.getElementsByClassName('MuiButtonBase-root MuiButton-root')[0].parentElement &&
        clona.getElementsByClassName('MuiButtonBase-root MuiButton-root')[0].parentElement.parentElement &&
        clona.getElementsByClassName('MuiButtonBase-root MuiButton-root')[0].parentElement.parentElement.parentElement
    ) {
        const botoes = clona.getElementsByClassName('MuiButtonBase-root MuiButton-root');
        for (let index = botoes.length - 1; index >= 0; index--) {
            const element = botoes[index];
            element.remove()
        }

        var removeHeader = clona.getElementsByClassName('p-datatable-thead')[0].getElementsByClassName('p-resizable-column');
        removeHeader[removeHeader.length - 1].remove();
    }

    return clona;
}

export const exportXLS = (objPage, idTable) => {
    if (objPage === null || objPage === undefined || objPage.length <= 0) {
        Alert.error('', 'Não há dados a serem exportados.');
        return;
    }

    import('xlsx').then(xlsx => {

        const worksheet = xlsx.utils.table_to_sheet(tabelaHigienizada(idTable), {dateNF: 'mm/dd/yyyy;@', cellDates: true, raw: true});
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAsExcelFile(excelBuffer, 'registros');
    });
}

const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
}

export const exportPdf = (objPage, idTable, props) => {

    if (objPage === null || objPage === undefined ||  objPage.length <= 0) {
        Alert.error('', 'Não há dados a serem exportados.');
        return;
    }

    import('jspdf').then(jsPDF => {
        import('jspdf-autotable').then(() => {
            const doc = new jsPDF.jsPDF('landscape', 'px', 'a4');

            doc.autoTable({
                html: tabelaHigienizada(idTable), createdCell: function ({ cell, row }) {
                    if (row.section === 'head') {
                        cell.styles.fillColor = props.theme.palette.primary.main;
                    }
                }
            });

            doc.save('registros.pdf');
        })
    })

}

export const HeaderExporter = ({ objPage, id, refTable, props }) => {

    return (
    <Grid container spacing={6}>
        <Grid item>
            <Button
                label="Exportar"
                variant="outlined"
                color="primary"
                onClick={() => exportXLS(objPage, id)}
                startIcon={<FaFileExcel />}>
                XLS
            </Button>
        </Grid>
        <Grid item>
            <Button
                label="Exportar"
                variant="outlined"
                color="primary"
                onClick={() => exportCSV(refTable, objPage)}
                startIcon={<FaFileCsv />}>
                CSV
            </Button>
        </Grid>
        <Grid item>
            <Button
                label="Exportar"
                variant="outlined"
                color="primary"
                onClick={() => exportPdf(objPage, id, props)}
                startIcon={<FaFilePdf />}>
                PDF
            </Button>
        </Grid>
        </Grid>
    )

}