//import axios from "../utils/axios";
import Axios from "axios";

function buscar(tipoBranding) {
  return new Promise((resolve, reject) => {
    Axios.get(`/branding/${tipoBranding}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function create({
  logomarca,
  baner,
  cor,
  url,
  bancos,
  urlTermos,
  header,
  whatsapp,
  subheader,
  subheader2,
  header2,
  scripts,
  rodape,
  exibirFormularioHeader,
  tipoBranding
}) {
  const formData = new FormData();

  formData.append("logomarca", logomarca);
  formData.append("baner", baner);
  formData.append("whatsapp", whatsapp);
  formData.append("cor", cor);
  formData.append("header", header);
  formData.append("subheader", subheader);
  formData.append("url", url);
  formData.append("bancos", bancos);
  formData.append("urlTermos", urlTermos);

  formData.append("subheader2", subheader2);
  formData.append("header2", header2);
  formData.append("scripts", scripts);
  formData.append("rodape", rodape);
  formData.append("exibirFormularioHeader", exibirFormularioHeader);
  formData.append("tipoBranding", tipoBranding);


  return Axios.post(`/branding`, formData, {
    headers: { "content-type": "multipart/form-data;" },
  });
}

const BrandingService = {
  buscar,
  create,
};

export default BrandingService;
