import React from "react";
import {
    PlusSquare, ShoppingCart, Users
} from "react-feather";
import { BiMessageAltCheck } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { RiDashboardLine, RiLockPasswordLine } from "react-icons/ri";
import async from "../components/Async";
import Empresas from "../pages/empresa/Empresas";
import { AiOutlineBank } from "react-icons/ai";
import Empresa from "../pages/cadastro/empresa/Empresa";

const ContratosConsignado = async(() => import("../pages/relatorio/contratosConsignado"));
const Parceiro = async(() => import("../pages/cadastro/parceiro/Parceiro"));
const Leads = async(() => import("../pages/relatorio/leads"));
const Contratos = async(() => import("../pages/relatorio/contratos"));
const Clientes = async(() => import("../pages/relatorio/clientes"));
const Banco = async(() => import("../pages/cadastro/banco/Banco"));
const Convenio = async(() => import("../pages/cadastro/convenio/Convenio"));
const Mensagem = async(() => import("../pages/mensagem/Mensagem"));
const ManterCliente = async(() => import("../pages/cadastro/cliente/ManterCliente"));
const ResetPasswordInputSenha = async(() => import("../pages/auth/ResetPasswordInputSenha"));
const RelatorioLogUsuario = async(() => import("../pages/relatorio/logusuario/RelatorioLogUsuario"));
const Parametro = async(() => import("../pages/cadastro/parametro/Parametro"));
const AlterarSenha = async(() => import("../pages/cadastro/alterarsenha/AlterarSenha"));
const Perfis = async(() => import("../pages/cadastro/perfis/Perfis"));
const PesquisarCliente = async(() => import("../pages/cadastro/cliente/PesquisarCliente"));
const RelatorioUsuarios = async(() => import("../pages/relatorio/usuarios/RelatorioUsuarios"));
const Dashboard = async(() => import("../pages/Analytics"));

const ConsultaIN100 = async(() => import("../pages/relatorio/consultaIN100"));


// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));

// Auth components
const SignUp = async(() => import("../pages/auth/SignUp"));
const SignIn = async(() => import("../pages/auth/SignIn"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));
const SignInCadastro = async(() => import("../pages/auth/SignInCadastro"));

// Dashboards components
const Settings = async(() => import("../pages/manutencao/Settings"));

// Protected routes
const ProtectedPage = async(() => import("../pages/protected/ProtectedPage"));

const suporteRoutes = {
    path: "/",
    header: "Suporte",
    permission: [],
    perfil: ['SUPORTE', 'FINANCEIRO'],
    children: [
    ],
    component: null,
    guard: AuthGuard,
};

const mensagensRoutes = {
    id: "Mensagens",
    path: "/mensagens",
    perfil: ['SUPORTE', 'FINANCEIRO'],
    icon: <BiMessageAltCheck />,
    permission: [],
    component: Mensagem,
    children: null,
    guard: AuthGuard,
};



const consultasRoutes = {
    id: "Cadastros",
    path: "/cadastros",
    permission: ['manutencao_perfis', 'manter_parametros', 'manter_pessoa', 'manter_banco', 'manter_parceiro'],
    icon: <PlusSquare />,
    guard: AuthGuard,
    children: [



        {
            path: "/cliente/cadastro/:idMutuarioParam",
            hiddenSidebar: true,
            permission: ['manter_pessoa'],
            component: ManterCliente,
            guard: AuthGuard,
        },
        {
            path: "/cliente/cadastro",
            hiddenSidebar: true,
            permission: ['manter_pessoa'],
            component: ManterCliente,
            guard: AuthGuard,
        },
        {
            name: "Pessoa",
            path: "/cliente/pesquisa",
            permission: ['manter_pessoa'],
            component: PesquisarCliente,
            guard: AuthGuard,
        },
        {
            name: "Bancos",
            path: "/banco",
            permission: ['manter_banco'],
            component: Banco,
            guard: AuthGuard,
        },
        {
            name: "Convênio",
            path: "/convenio",
            permission: ['manter_convenio'],
            component: Convenio,
            guard: AuthGuard,
        },
        {
            name: "Parceiros",
            path: "/parceiro",
            permission: ['manter_banco'],
            component: Parceiro,
            guard: AuthGuard,
        },

        {
            path: "/cadastros/perfis",
            name: "Perfis",
            permission: ['manutencao_perfis'],
            component: Perfis,
            guard: AuthGuard,
        },
        {
            path: "/cadastros/parametros",
            name: "Configurações",
            permission: ['manter_parametros'],
            component: Parametro,
            guard: AuthGuard,
        },
    ],
    component: null
};

const pagesRoutes = {
    id: "Relatórios",
    path: "/relatorios",
    permission: ['relatorio_leads', 'rel_log_usuario', 'relatorio_usuarios', 'relatorio_contratos_fgts', 'manter_pessoa'],
    icon: <HiOutlineDocumentReport />,
    children: [

        {
            path: "/relatorios/usuarios",
            name: "Usuários",
            permission: ['relatorio_usuarios'],
            component: RelatorioUsuarios,
            guard: AuthGuard,
        },


        
        {
            path: "/relatorios/auditoria",
            name: "Log do Usuário",
            permission: ['rel_log_usuario'],
            component: RelatorioLogUsuario,
            guard: AuthGuard,
        },
        {
            path: "/relatorios/contratos",
            name: "Contratos FGTS",
            permission: ['relatorio_contratos_fgts'],
            component: Contratos,
            guard: AuthGuard,
        },
        {
            path: "/relatorios/contratos/consignado",
            name: "Contratos Consignado",
            permission: ['relatorio_contratos_fgts'],
            component: ContratosConsignado,
            guard: AuthGuard,
        },
        {
            path: "/relatorios/clientes",
            name: "Clientes",
            permission: ['manter_pessoa'],
            component: Clientes,
            guard: AuthGuard,
        },
        {
            path: "/relatorios/leads",
            name: "Leads",
            permission: ['relatorio_leads'],
            component: Leads,
            guard: AuthGuard,
        },
        {
            path: "/relatorios/in100",
            name: "Consulta IN100",
            component: ConsultaIN100,
            permission: [],
            guard: AuthGuard,
        }
    ],
    component: null
};

const authRoutes = {
    id: "Auth",
    path: "/",
    permission: [],
    icon: <Users />,
    children: [
        {
            path: "/",
            name: "Login",
            component: SignIn
        },
        {
            path: "/auth/posCadastro/:token",
            hiddenSidebar: true,
            component: SignInCadastro
        },
        {
            path: "/auth/reset-password",
            name: "Esqueci Minha Senha",
            component: ResetPassword
        },
        {
            path: "/recuperarSenha/:hash",
            name: "Alterar Senha",
            component: ResetPasswordInputSenha
        },
        {
            path: "/cadastro",
            name: "Cadastro",
            component: SignUp
        },
        {
            path: "/auth/404",
            name: "404 Page",
            component: Page404
        }, {
            path: "/auth/500",
            name: "500 Page",
            component: Page500
        },
    ],
    component: null
};



const dashboardsRoutes = {
    id: "Início",
    path: "/app",
    permission: [],
    icon: <RiDashboardLine />,
    containsHome: true,
    component: Dashboard,
    guard: AuthGuard,

};


const meuPontoRoutes = {
    path: "/",
    header: "Minha Área",
    permission: [],
    children: [
    ],
    component: null,
    guard: AuthGuard,
};


const alterarSenha = {
    id: "Alterar Senha",
    permission: [],
    path: "/alterarSenha",
    icon: <RiLockPasswordLine />,
    component: AlterarSenha,
    children: null,
    guard: AuthGuard,
};

const meuPerfil = {
    id: "Meus Dados",
    permission: [],
    path: "/manutencao/profile",
    icon: <CgProfile />,
    component: Settings,
    children: null,
    guard: AuthGuard,
};

const empresasRoutes = {
    id: "Empresas",
    path: "/empresas",
    perfil: ['SUPORTE', 'FINANCEIRO'],
    icon: <AiOutlineBank />,
    permission: [],
    component: Empresas,
    children: null,
    guard: AuthGuard,
};


// This route is only visible while signed in
const protectedPageRoutes = {
    id: "Private",
    permission: [],
    path: "/app",
    component: ProtectedPage,
    children: null,
    guard: AuthGuard
};


const empresaRoute = {
    id: "Empresa",
    path: "/empresa",
    permission: [],
    icon: <ShoppingCart />,
    component: Empresa,
    children: null,
    guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
    dashboardsRoutes,
    consultasRoutes,
    pagesRoutes,
    suporteRoutes,
    empresasRoutes,
    mensagensRoutes,
    empresaRoute,
    meuPontoRoutes,
    meuPerfil,
    alterarSenha
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];


// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
    dashboardsRoutes,
    consultasRoutes,
    pagesRoutes,
    suporteRoutes,
    empresasRoutes,
    mensagensRoutes,
    meuPontoRoutes,
    meuPerfil,
    alterarSenha
];
