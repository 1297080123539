
import {
    Box,
    Breadcrumbs as MuiBreadcrumbs,
    Link,
    Typography
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import React from 'react';
import { NavLink } from "react-router-dom";
import styled from 'styled-components';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const BreadcrumbsNav = ({ linkLabel = 'Início', label, children, video }) => {
    
    return (

        <>
      
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to={'/app'}>
                {linkLabel} 
            </Link>
            {children}
                {label && <Typography>{label}</Typography>}                 
            </Breadcrumbs>
            <Box my={3} />

        </>
    )


};


export default BreadcrumbsNav;