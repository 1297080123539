import { Avatar, Chip } from '@material-ui/core';
import React from 'react';
import PessoaService from '../../services/PessoaService';
import AutoCompleteAsyncPersist from './autocomplete/AutoCompleteAsyncPersist';

const PessoaAutoComplete = (props) => {

    const handleFetchAPI = async (valueSearch) => {
        const response = await PessoaService.pesquisar(null, null, valueSearch, null, 0);
        return response.content;
    }

    const renderTags = (tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
            <Chip
                color="secondary"
                avatar={<Avatar alt={option.nome} src={option.caminhoFoto} />}
                label={option.nome}
                {...getTagProps({ index })}
            />)
        )
    }

    return (
        <AutoCompleteAsyncPersist
            name="autoPessoa"
            renderTags={renderTags}
            label="Pessoa"
            itemLabel={'nome'}
            fullWidth
            onFetchAPI={handleFetchAPI}
            {...props}
        />
    )
}

export default PessoaAutoComplete
