import { Box } from "@material-ui/core";
import PropTypes from 'prop-types';

const WrapperTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0} my={5}>
                    {children}
                </Box>
            )}
        </div>
    );
}
export default WrapperTabPanel;

WrapperTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
