import React, { useState } from 'react';

import TextField from './TextField';
import MaskUtil from '../../../utils/MaskUtil';
import ValidadorUtil from '../../../utils/ValidadorUtil';

const TextFieldCNPJ = (props) => {


    const [cnpjInvalido, setCnpjInvalido] = useState();

    const validar = (cnpj) => {

        if (!ValidadorUtil.validarCNPJ(cnpj)) {
            setCnpjInvalido('O CNPJ informado não é válido, verifique.');
        } else {
            setCnpjInvalido(undefined);
        }

    }


    return (

        <TextField
            {...props}
            onChange={(e) => {
                var val = MaskUtil.undoMask(e.target.value);
                props.onChange(val);
            }}
            onBlur={(e) => {
                if (props.onBlur) {
                    props.onBlur(e);
                }
                validar(MaskUtil.undoMask(e.target.value));
            }}
            value={MaskUtil.cnpj(props.value)}
            error={props.error || Boolean(cnpjInvalido)}
            helperText={props.helperText || cnpjInvalido}

        >
        </TextField>


    )
}

export default TextFieldCNPJ
