import { Button, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PermissionUtil from '../../../utils/PermissionUtil';
import StringUtil from '../../../utils/StringUtil';
import TextField from '../textfield/TextField';

const filter = createFilterOptions();

const WAIT_INTERVAL = 1000
let timerID

const AutoCompleteAsyncPersist = (props) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [valueInput, setValueInput] = useState();
    //const [openDialog, setOpenDialog] = useState(false);
    const { user } = useSelector(state => state.authReducer);
    const [loading, setLoading] = useState(false);

    const chamaWs = (texto) => {
        clearTimeout(timerID);

        setLoading(true);
        timerID = setTimeout(() => {
            props.onFetchAPI(texto)
                .then(response => {
                    setOptions(response);
                }).finally(() => setLoading(false));
        }, WAIT_INTERVAL);
    }


    const handleOpen = () => {
        setOpen(true);
        chamaWs(null);
    }

    const handleClose = () => {
        setOpen(false);
        setOptions([]);
    }

    const renderOption = (option) => {
        return (
            <>
                {option.create ?
                    <div>
                        {PermissionUtil.hasPermissions(props.permission, user) && <Button variant="text" color="primary" onClick={option.onclick}>{'Adicionar'}</Button>}
                        <Typography variant="caption">{StringUtil.fetchFromObject(option, props.itemLabel)}</Typography>
                    </div>
                    :
                    <Typography noWrap>{StringUtil.fetchFromObject(option, props.itemLabel)}</Typography>
                }
            </>
        )
    }

    return (
        <>
            <Autocomplete
                open={open}
                onOpen={() => handleOpen()}
                onClose={() => handleClose()}
                getOptionSelected={(option, value) => {
                    return option.id === value.id
                }}
                getOptionLabel={(option) => StringUtil.fetchFromObject(option, props.itemLabel)}
                options={options}
                loading={loading}
                disableClearable
                renderOption={(option) => renderOption(option)}
                {...props}
                onChange={(event, value, reason, details) => {
                    if (reason === 'remove-option') {
                        props.onRemove(details.option, value, reason, details);
                    } else if (reason === 'select-option') {
                        props.onChange(details.option, value, reason, details);
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        required={props.required}
                        error={props.error}
                        helperText={props.helperText}
                        onBlur={props.onBlur}
                        style={props.style}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue && params.inputValue !== '' && valueInput !== params.inputValue && params.inputValue.length > 2) {
                        setValueInput(params.inputValue);
                        chamaWs(params.inputValue)
                    }
                    if (params.inputValue === '' && valueInput !== params.inputValue) {
                        setValueInput(params.inputValue);
                        chamaWs(null);
                    }
                    filtered.push({
                        inputValue: params.inputValue,
                        name: params.inputValue,
                        create: true,
                        onclick: () => props.setShowCadastro(true)
                    });
                    return filtered;
                }}

            />
            {props.showCadastro && props.renderPersist(valueInput)}
        </>
    )
}

AutoCompleteAsyncPersist.propTypes = {
    onFetchAPI: PropTypes.func.isRequired,
    renderPersist: PropTypes.func.isRequired,
    permission: PropTypes.array.isRequired

}

AutoCompleteAsyncPersist.defaultProps = {
    onRemove: () => { }
}

export default AutoCompleteAsyncPersist
