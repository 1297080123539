import React from 'react';

import TextField from './TextField';
import MaskUtil from '../../../utils/MaskUtil';

const TextFieldCEP = (props) => {

    return (

        <TextField
            {...props}
            onChange={(e) => {
                var val = MaskUtil.undoMask(e.target.value);
                props.onChange(val);
            }}
            value={MaskUtil.cep(props.value)}

        >
        </TextField>


    )
}

export default TextFieldCEP
