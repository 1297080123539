import { Button } from "@material-ui/core";
import React from "react";
import { Check } from "react-feather";

const CadastrarButton = ({
  onClick = () => {},
  title = "Cadastrar",
  type = "submit",
  style,
  disabled = false,
}) => {
  return (
    <>
      <Button
        style={style}
        type={type}
        variant="contained"
        color="primary"
        startIcon={<Check />}
        onClick={() => onClick()}
        disabled={disabled}
      >
        {title}
      </Button>
    </>
  );
};

export default CadastrarButton;
