import { differenceInDays, differenceInMinutes, format, addDays, subDays, isAfter, isFriday, isMonday, isSaturday, isSunday, isThursday, isTuesday, isWednesday, lastDayOfMonth, parse, setDate, addHours, addMinutes, differenceInSeconds, subMonths } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import _ from 'lodash';
import moment from 'moment';


function localDateTimeToDate(localDateTime) {

  if (!localDateTime) {
    return undefined;
  }
  return new Date(localDateTime.year, localDateTime.monthValue - 1, localDateTime.dayOfMonth, localDateTime.hour, localDateTime.minute, localDateTime.second);
}

function dateToLocalDateTime(localDateTime) {

  if (!localDateTime) {
    return undefined;
  }

  return {
    year: localDateTime.getFullYear(),
    monthValue: localDateTime.getMonth() + 1,
    dayOfMonth: localDateTime.getDate(),
    hour: localDateTime.getHours(),
    minute: localDateTime.getMinutes(),
    second: localDateTime.getSeconds()
  }
}


function localDateToDate(localDateTime) {

  if (!localDateTime) {
    return undefined;
  }
  return new Date(localDateTime.year, localDateTime.monthValue - 1, localDateTime.dayOfMonth);
}

function subtrairDia(date, quantidadeDiaSubtrair) {
  if (!date) {
    return null;
  }
  return subDays(date, quantidadeDiaSubtrair);
}

function subtrairMes(date, quantidadeDiaSubtrair) {
  if (!date) {
    return null;
  }
  return subMonths(date, quantidadeDiaSubtrair);
}

function adicionarDia(date, quantidadeDiaSubtrair) {
  if (!date) {
    return null;
  }
  return addDays(date, quantidadeDiaSubtrair);
}


function adicionarHoras(date, quantidadeAdicionar) {

  if (!date) {
    return null;
  }

  return addHours(date, quantidadeAdicionar);
}


function formataDataCompleta(date, formato = 'DD/MM/YYYY') {
  return format(date, formato);
}

function dateToString(date) {
  return String().concat(date.getFullYear(), '-', date.getMonth() + 1, '-', date.getDate());
}

function timestampToDate(timestamp, mask = 'dd/MM/yyyy') {
  if (timestamp === null || timestamp === undefined || timestamp === '') {
    return '';
  }
  return format(timestamp, mask, { locale: pt });
}

function getHoraMinutoFmt(date) {
  return String().concat(_.padStart(date.getUTCHours(), 2, '0'), ':', _.padStart(date.getUTCMinutes(), 2, '0'), ':', _.padStart(date.getUTCSeconds(), 2, '0'));
}

function getStringDateTimeFmt(dateStr) {
  if (dateStr === undefined || dateStr === '' || dateStr === null) {
    return '';
  }
  const dateFmt = new Date(dateStr);
  const dia = _.padStart(dateFmt.getUTCDate(), 2, '0');
  const mes = _.padStart(dateFmt.getUTCMonth() + 1, 2, '0');
  return String().concat(dia, '/', mes, '/', dateFmt.getUTCFullYear(), ' ', this.getHoraMinutoFmt(dateFmt));
  // return String().concat(new Date('2011-04-11T10:20:30Z').toUTCString());
}

function stringToDate(str) {
  // ddMMyyyy -> new Date(yyyy, MM, dd)
  const dia = str.substring(0, 2);
  const mes = str.substring(2, 4);
  const ano = str.substring(4, 8);
  return new Date(`${ano}-${mes}-${dia}T07:00:00.000Z`); // Vergonha eterna
}

function timestampToDateTimeFmt(timestamp) {
  if (timestamp === undefined || timestamp === '' || timestamp === null) {
    return '';
  }
  const dateFmt = new Date(timestamp);
  const dia = _.padStart(dateFmt.getUTCDate(), 2, '0');
  const mes = _.padStart(dateFmt.getUTCMonth() + 1, 2, '0');
  const horas = _.padStart(dateFmt.getHours(), 2, '0');
  const minutos = _.padStart(dateFmt.getMinutes(), 2, '0');
  const segundos = _.padStart(dateFmt.getSeconds(), 2, '0');

  return String().concat(dia, '/', mes, '/', dateFmt.getUTCFullYear(), ' ', horas, ':', minutos, ':', segundos);
}


function stringDateToTime(str, mask = 'dd/MM/yyyy') {
  if (str === null || str === undefined) {
    return '';
  }
  return parse(str, mask, new Date()).getTime();
}

function minutoEmHoras(minutos) {
  const inicio = new Date();
  const fim = addMinutes(inicio, minutos < 0 ? minutos * -1 : minutos);

  const direferencaDias = diferencaEmDias(inicio, fim);

  var helperDate = addMinutes(new Date('2000-01-01 00:00:00'), minutos);
  const formatado = format(helperDate, 'HH:mm');

  if (direferencaDias > 0) {
    const itens = formatado.split(':');

    const somaHoras = parseInt(itens[0]) + (direferencaDias * 24);

    return somaHoras+":"+itens[1];

  }
  return formatado;
}

function getDataAtual() {
  return format(new Date().getTime(), 'yyyy-MM-dd', { locale: pt });
}

function getUltimoDiaDoMes(data) {
  if (data === undefined || data === null || data === '') {
    return null;
  }
  return lastDayOfMonth(data);
}

function setDiaAData(data, dia) {
  return setDate(data, dia);
}

function diferencaEmMinutos(dataInicio, dataFinal) {
  return differenceInMinutes(dataInicio, dataFinal);
}


function diferencaEmSegundos(dataInicio, dataFinal) {
  return differenceInSeconds(dataFinal, dataInicio);
}

function dataInicioMaiorFinal(dtInicio, dtFinal) {
  return isAfter(dtInicio, dtFinal);
}

function stringToDateFormat(date) {
  return Date(date);
}

function getDiaSemanaAtualDesc(dtAtual = new Date()) {
  switch (true) {
    case isSunday(dtAtual):
      return 'DOMINGO';
    case isMonday(dtAtual):
      return 'SEGUNDA';
    case isTuesday(dtAtual):
      return 'TERCA';
    case isWednesday(dtAtual):
      return 'QUARTA';
    case isThursday(dtAtual):
      return 'QUINTA';
    case isFriday(dtAtual):
      return 'SEXTA';
    case isSaturday(dtAtual):
      return 'SABADO';
    default:
      return 'INVALIDO';
  }
}

function tempoFormatado(data) {
  if (data === undefined || data === null) {
    return null;
  }

  if (timestampToDate(new Date()) === timestampToDate(data)) {
    return `Hoje às ${timestampToDate(data, 'HH:mm')}`;
  }

  if (moment(new Date()).subtract(1, 'days').format('D/MM/YYYY') === timestampToDate(data)) {
    return `Ontem às ${timestampToDate(data, 'HH:mm')}`;
  }

  return timestampToDate(data, "dd/MM/yyyy 'às' HH:mm");
}

function diaSemanaFormatado(dtAtual = new Date()) {
  switch (true) {
    case isSunday(dtAtual):
      return 'Domingo';
    case isMonday(dtAtual):
      return 'Segunda-Feira';
    case isTuesday(dtAtual):
      return 'Terça-Feira';
    case isWednesday(dtAtual):
      return 'Quarta-Feira';
    case isThursday(dtAtual):
      return 'Quinta-Feira';
    case isFriday(dtAtual):
      return 'Sexta-Feira';
    case isSaturday(dtAtual):
      return 'Sábado';
    default:
      return 'INVALIDO';
  }
}

function getDiaSemanaAbreviado(dtAtual = new Date()) {
  switch (true) {
    case isSunday(dtAtual):
      return 'Dom.';
    case isMonday(dtAtual):
      return 'Seg.';
    case isTuesday(dtAtual):
      return 'Ter.';
    case isWednesday(dtAtual):
      return 'Qua.';
    case isThursday(dtAtual):
      return 'Qui.';
    case isFriday(dtAtual):
      return 'Sex.';
    case isSaturday(dtAtual):
      return 'Sáb.';
    default:
      return 'Inv.';
  }
}

function diferencaEmDias(dataInicio, dataFinal) {
  return differenceInDays(dataFinal, dataInicio);
}

function dateTimeToString(value, mask = "yyyy-MM-dd'T'HH:mm") {
  if (value === null || value === undefined || (!value instanceof Date)) {
    return '';
  }
  return format(value.getTime(), mask);
}

const DateUtil = {
  tempoFormatado, adicionarHoras, adicionarDia, formataDataCompleta, diaSemanaFormatado, getDiaSemanaAbreviado,
  stringToDateFormat, subtrairDia, dateToString, timestampToDate, getHoraMinutoFmt, getStringDateTimeFmt,
  stringToDate, timestampToDateTimeFmt, stringDateToTime, minutoEmHoras, getDataAtual, getUltimoDiaDoMes,
  setDiaAData, diferencaEmMinutos, dataInicioMaiorFinal, getDiaSemanaAtualDesc, diferencaEmDias,
  dateTimeToString, localDateTimeToDate, localDateToDate, dateToLocalDateTime, diferencaEmSegundos, subtrairMes
}
export default DateUtil;
