import {
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Paper,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useFormik } from "formik";
import { Column } from "primereact/column";
import React, { useState } from "react";
import { Check } from "react-feather";
import { Helmet } from "react-helmet";
import { FaBan } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import styled from "styled-components/macro";
import * as Yup from "yup";
import Alert from "../../components/alert/Alert";
import DataTable from "../../components/datatable/DataTable";
import Dialog from "../../components/Dialog";
import BreadcrumbsNav from "../../components/icarus/BreadcrumbsNav";
import CadastrarButton from "../../components/icarus/buttons/CadastrarButton";
import EditarButtonTable from "../../components/icarus/buttons/EditarButtonTable";
import PesquisarButton from "../../components/icarus/buttons/PesquisarButton";
import TextField from "../../components/icarus/textfield/TextField";
import TextFieldCNPJ from "../../components/icarus/textfield/TextFieldCNPJ";
import Loading from "../../components/Loading";
import EmpresaService from "../../services/EmpresaService";
import CadastrarEmpresa from "./CadastrarEmpresa";
import EditarEmpresa from "./EditarEmpresa";

const validationSchema = Yup.object().shape({
  nomeFiltro: Yup.string().nullable().min(3).max(255),
  documento: Yup.string().nullable().min(3).max(255),
  tipo: Yup.string().nullable(),
  statusNfse: Yup.string().nullable(),
});

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

function Empresas(props) {
  const [objPage, setObjPage] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [carregando, setCarregando] = useState(false);
  const [empresaSelecionado, setEmpresaSelecionado] = useState();
  const [page, setPage] = useState(0);
  const [excluirPermanentemente, setExcluirPermanentemente] = useState();
  const [selecionado, setSelecionado] = useState();
  const [preparaExclusao, setPreparaExclusao] = useState(false);
  const [showCadastrar, setShowCadastrar] = useState(false);

  const formik = useFormik({
    initialValues: {
      nomeFiltro: undefined,
      documento: undefined,
      tipo: undefined,
      statusNfse: undefined,
      modoCobranca: undefined,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      pesquisar(0);
    },
  });

  const pesquisar = (page) => {
    setPage(page);
    setCarregando(true);

    EmpresaService.pesquisa(
      formik.values.nomeFiltro,
      page,
      formik.values.documento,
      formik.values.tipo,
      formik.values.statusNfse,
      formik.values.modoCobranca
    )
      .then((response) => {
        setObjPage(response);
      })
      .finally(() => setCarregando(false));
  };

  const onPageDataTable = (page) => {
    pesquisar(page);
  };

  const editar = (data) => {
    setEmpresaSelecionado(data);
    setShowDialog(true);
  };

  const handleConfirmExcluir = () => {
    if (
      !excluirPermanentemente ||
      excluirPermanentemente !== "excluir permanentemente"
    ) {
      Alert.error(
        "Erro",
        'Você deve digitar a palavra "excluir permanentemente" no campo de texto, para confirmar a exclusão'
      );
      return;
    }

    setCarregando(true);

    EmpresaService.deletar(selecionado.idEmpresa)
      .then(() => {
        pesquisar(page);
        setSelecionado(undefined);
        setExcluirPermanentemente("");
        setPreparaExclusao(false);
        Alert.success("Sucesso", "Empresa excluida com sucesso");
      })
      .finally(() => {
        setCarregando(false);
      });
  };

  const actionTemplate = (rowData, column) => {
    return (
      <>
        {preparaExclusao && (
          <Dialog
            title="Excluir Empresa?"
            show={preparaExclusao}
            onRequestClose={() => setPreparaExclusao(false)}
          >
            <Typography variant={"subtitle1"} className="warnColor">
              Atenção! Você tem certeza que deseja excluir a empresa{" "}
              <b>{selecionado && selecionado.nome}</b>? todas as informações
              como pontos, férias, afastamentos, fechamentos serão perdidas.
              Esta operação não tem volta.
            </Typography>
            <Box my={5} />
            <Typography variant={"subtitle2"}>
              Para confirmar a exclusão, digite excluir permanentemente no
              campo.
            </Typography>
            <Box my={5} />
            <TextField
              name="excluirLabel"
              label="excluir permanentemente"
              value={excluirPermanentemente}
              fullWidth
              my={10}
              onChange={(e) => setExcluirPermanentemente(e.target.value)}
            />
            <Box my={5} />

            {carregando ? (
              <Loading />
            ) : (
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => setPreparaExclusao(false)}
                  color="secondary"
                  startIcon={<ImCancelCircle />}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleConfirmExcluir();
                  }}
                  color="default"
                  startIcon={<Check />}
                >
                  Confirmar
                </Button>
              </DialogActions>
            )}
          </Dialog>
        )}
        <Grid container spacing={2}>
          <Grid item>
            <EditarButtonTable onClick={() => editar(rowData)} />
          </Grid>

          <Grid item>
            <Button
              type="button"
              variant="contained"
              style={{ backgroundColor: "red", color: "white" }}
              title={"Deletar"}
              startIcon={<FaBan />}
              onClick={() => {
                setPreparaExclusao(true);
                setSelecionado(rowData);
              }}
            >
              Deletar
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderTable = () => {
    return (
      <DataTable
        carregando={carregando}
        objPage={objPage}
        onPage={onPageDataTable}
      >
        <Column
          field="nome"
          header="Nome"
          body={(rowData) => (
            <>
              <span className="p-column-title">Nome</span>
              {rowData.nome}
            </>
          )}
        />

        <Column
          field="documentoFormatado"
          header="CPF/CNPJ"
          body={(rowData) => (
            <>
              <span className="p-column-title">CPF/CNPJ</span>
              {rowData.documentoFormatado}
            </>
          )}
        />

        <Column
          field="email"
          header="Email"
          body={(rowData) => (
            <>
              <span className="p-column-title">Email</span>
              {rowData.email}
            </>
          )}
        />

        <Column
          field="telefoneFormatado"
          header="Telefone"
          body={(rowData) => (
            <>
              <span className="p-column-title">Telefone</span>
              {rowData.telefoneFormatado}
            </>
          )}
        />

        <Column
          field="statusFormatado"
          header="Status"
          body={(rowData) => (
            <>
              <span className="p-column-title">Status</span>
              {rowData.statusFormatado}
            </>
          )}
        />

        <Column body={actionTemplate} header="Ações" />
      </DataTable>
    );
  };

  const handleClose = () => {
    setShowDialog(false);
    pesquisar(page);
  };

  return (
    <React.Fragment>
      <Helmet title="Empresas" />

      <Typography variant="h4" gutterBottom display="inline">
        Empresas
      </Typography>

      <BreadcrumbsNav label="Empresas" />

      <Divider my={6} />

      {showDialog && (
        <EditarEmpresa
          show={showDialog}
          onRequestClose={() => handleClose()}
          data={empresaSelecionado}
        />
      )}
      {showCadastrar && (
        <CadastrarEmpresa
          show={showCadastrar}
          onRequestClose={() => setShowCadastrar(false)}
        />
      )}

      <Paper elevation={5}>
        <Card>
          <CardContent>
            <Typography component="h5" variant="h5">
              Pesquisar
            </Typography>
            <Box my={6} />
            <form noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={6}>
                <Grid item md={4} sm={12} xs={12}>
                  <TextField
                    name="nomeFiltro"
                    label="Nome"
                    fullWidth
                    my={10}
                    value={formik.values.nomeFiltro}
                    error={Boolean(
                      formik.touched.nomeFiltro && formik.errors.nomeFiltro
                    )}
                    helperText={
                      formik.touched.nomeFiltro && formik.errors.nomeFiltro
                    }
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <TextFieldCNPJ
                    name="documento"
                    label="CPF/CNPJ"
                    fullWidth
                    my={10}
                    value={formik.values.documento}
                    error={Boolean(
                      formik.touched.documento && formik.errors.documento
                    )}
                    helperText={
                      formik.touched.documento && formik.errors.documento
                    }
                    onBlur={formik.handleBlur}
                    onChange={(e) => formik.setFieldValue("documento", e)}
                  />
                </Grid>

                <Grid item md={10}>
                  {carregando ? (
                    <Loading />
                  ) : (
                    <Grid container spacing={6}>
                      <Grid item>
                        <PesquisarButton />
                      </Grid>
                      <Grid item>
                        <CadastrarButton
                          onClick={() => setShowCadastrar(true)}
                          type="button"
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Paper>
      <Box my={5} />
      <Paper elevation={5}>
        <Card>
          <CardContent>
            <Typography component="h5" variant="h5">
              Resultado
            </Typography>
            <Box my={6} />
            {renderTable()}
          </CardContent>
        </Card>
      </Paper>
    </React.Fragment>
  );
}

export default Empresas;
